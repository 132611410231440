import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import HighchartsVue from "highcharts-vue";
import store from "./store";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from 'vue2-google-maps'


import Notifications from "vue-notification";
import VueCompositionAPI from "@vue/composition-api";


Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(HighchartsVue);
Vue.use(VueApexCharts);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
    loading: 'async'  
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})
Vue.component("apexchart", VueApexCharts);
new Vue({
  beforeCreate() {
    this.$store.commit("setUserAndTokenOnRefresh");
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
