<template>
    <div>
        <apexchart type="area" height="50" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [{
                data: this.data,
            }],
            chartOptions: {
                chart: {
                    height: 110,
                    width: "100%",
                    type: 'area',
                    parentHeightOffset: 0,
                    toolbar: { show: false },
                    sparkline: { enabled: true },
                },
                tooltip: { enabled: false },
                markers: {
                    colors: 'transparent',
                    strokeColors: 'transparent',
                },
                grid: { show: false },
                colors: [this.color],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 0.8,
                        opacityFrom: 0.6,
                        opacityTo: 0.1,
                    },
                },
                dataLabels: { enabled: false },
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                xaxis: {
                    show: true,
                    lines: { show: false },
                    labels: { show: false },
                    stroke: { width: 0 },
                    axisBorder: { show: false },
                },
                yaxis: {
                    stroke: { width: 0 },
                    show: false,
                },
            },
        }
    },
    //props
    props: {
        color: {
            type: String,
            default: "#80CBC4"
        },
        data: {
            type: Array,
            default: () => []
        }
    },

    watch: {
        data: {
            handler: function (val) {
                this.series = [{
                    data: val,
                }]
            },
            deep: true
        }
    },

    methods: {

    }
}
</script>