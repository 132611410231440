import api from "../../../api";

export default {
    //Reset State 
    resetFacebookState({ commit }) {
        commit("resetFacebookState")
    },
    // Adaccounts
    getFacebookAdaccounts({ commit }) {
        return api.facebook.getAllAdaccounts().then((response) => {
            const payload = {};
            payload.ad_accounts = response;
            commit("setFacebookAdaccounts", payload);
        });
    },
    getAdaccount({ commit }, adAccountId) {
        return api.facebook.getOneAdaccount(adAccountId).then((response) => {
            const payload = {};
            payload.single_ad_account = response;
            commit("setSingleAdaccount", payload);
        });
    },
    getAdaccountInsights({ commit }, req_body) {
        return api.facebook.getAdaccountInsights(req_body).then((response) => {
            const payload = {};
            payload.facebook_adaccount_insights = response;
            commit("setFacebookAdaccountInsights", payload);
        });
    },
    checkReportStatus({ commit }, req_body) {
        return api.facebook.checkReportStatus(req_body).then((response) => {
            const payload = {};
            payload.adaccount_report_status = response.async_status;
            commit("setAdaccountReportStatus", payload);
        });
    },
    getAdaccountInsightsReportId({ commit }, req_body) {
        return api.facebook.getAdaccountInsightsReportId(req_body).then((response) => {
            const payload = {};
            payload.adaccount_report_run_id = response.id;
            commit("setAdaccountReportRun", payload);
        });
    },

    // Campaigns
    createFacebookCampaign({ commit }, data) {
        return api.facebook.createCampaign(data).then(response => {
            const payload = {};
            payload.facebook_campaign = response;
            commit("setFacebookCampaign", payload);
        })
    },

    updateFacebookCampaign({ commit }, data) {
        return api.facebook.updateCampaign(data).then(response => {
            const payload = {};
            payload.updated_facebook_campaign = response;
            commit("setUpdatedFacebookCampaign", payload);
        })
    },


    updateFacebookCampaignFees({ commit }, data) {
        return api.facebook.updateCampaignFees(data).then(response => {
            const payload = {};
            payload.updated_facebook_campaign_fees = response;
            commit("setUpdatedFacebookCampaignFees", payload);
        })
    },

    getFacebookCampaigns({ commit }, campaign_id) {
        // if (!adAccountId) return;
        return api.facebook.getAllCampaigns(campaign_id).then((response) => {
            const payload = {};
            payload.facebook_campaigns = response;
            commit("setFacebookCampaigns", payload);
        });
    },
    getMediaPalSocialCampaigns({ commit }) {
        return api.facebook.getMediaPalSocialCampaigns().then(response => {
            const payload = {};

            payload.mediapal_social_campaigns = response;
            commit("setMediapalSocialCampaigns", payload);
        });
    },
    getOneFacebookCampaign({ commit }, ids) {
        return api.facebook.getOneCampaign(ids).then((response) => {
            const payload = {};
            payload.facebook_campaign = response;
            commit("setFacebookCampaign", payload);
        });
    },

    deleteFacebookCampaign({ commit }, ids) {
        return api.facebook.deleteCampaign(ids).then((response) => {
            const payload = {};
            payload.facebook_campaign = response;
            commit("setFacebookCampaign", payload);
        });
    },

    getFacebookCampaignInsights({ commit }, ids) {
        return api.facebook.getCampaignInsights(ids).then((response) => {
            const payload = {};
            payload.facebook_campaign_insights = response[0];
            commit("setFacebookCampaignInsights", payload);
        });
    },
    getInsightsByDateRange({ commit }, req_body) {
        return api.facebook.getCampaignInsightsByDateRange(req_body).then(response => {
            const payload = {};
            payload.facebook_campaign_insights = response[0];
            commit("setFacebookCampaignInsights", payload);
        })
    },
    getTimeBasedFacebookCampaignInsights({ commit }, req_body) {
        return api.facebook
            .getTimeBasedCampaignInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_time_based_campaign_insights = response;
                commit("setTimedCampaignInsights", payload);
            });
    },

    getCombinedFacebookCampaignInsights({ commit }, req_body) {
        return api.facebook
            .getCombinedFacebookCampaignInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_combined_campaign_insights = response;
                commit("setCombinedCampaignInsights", payload);
            });
    },
    getAgeBasedFacebookCampaignInsights({ commit }, req_body) {
        return api.facebook
            .getTimeBasedCampaignInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_age_based_campaign_insights = response;
                commit("setAgeCampaignInsights", payload);
            });
    },

    // Adsets
    createAdset({ commit }, req_body) {
        return api.facebook.createAdset(req_body).then(response => {
            const payload = {};
            payload.facebook_single_adset = response;
            commit("setFacebookAdset", payload);
        }).catch(err => {
            const payload = {};
            payload.special_error = err.errors;
            commit("setSpecialError", payload);
        });
    },
    updateAdset({ commit }, req_body) {
        return api.facebook.updateAdset(req_body).then(response => {
            const payload = {};
            payload.update_facebook_adset = response;
            commit("setUpdatedFacebookAdset", payload);
        }).catch(e => console.error(e));
    },

    deleteAdset({ commit }, req_body) {
        return api.facebook.deleteAdset(req_body).then(response => {
            const payload = {};
            payload.deleted_facebook_adset = response;
            commit("setDeletedFacebookAdset", payload);
        });
    },
    getFacebookAdsetsByAccount({ commit }, adAccountId) {
        return api.facebook.getAllAdsets(adAccountId).then((response) => {
            const payload = {};
            payload.facebook_adsets_by_account = response;
            commit("setFacebookAdsetsByAccount", payload);
        });
    },
    getFacebookAdsetsByCampaign({ commit }, ids) {
        return api.facebook.getFacebookAdsets(ids).then((response) => {
            const payload = {};
            payload.facebook_adsets_by_campaign = response;
            commit("setFacebookAdsetsByCampaign", payload);
        });
    },
    getFacebookAdset({ commit }, ids) {
        return api.facebook.getFacebookAdset(ids).then((response) => {
            const payload = {};
            payload.facebook_single_adset = response;
            commit("setFacebookAdset", payload);
        });
    },
    getAdsetInsightsByDate({ commit }, req_body) {
        return api.facebook.getDatedAdsetinsights(req_body).then((response) => {
            const payload = {};
            payload.facebook_adset_insights = response;
            commit("setFacebookAdsetInsights", payload);
        });
    },
    getAdsetsInsights({ commit }, ids) {
        return api.facebook.getAdsetInsights(ids).then((response) => {
            const payload = {};
            payload.facebook_adset_insights = response;
            commit("setFacebookAdsetInsights", payload);
        });
    },
    getTimeBasedAdsetInsights({ commit }, req_body) {
        return api.facebook
            .getTimeBasedAdsetInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_time_based_adset_insights = response;
                commit("setTimeBasedAdsetInsights", payload);
            });
    },
    getRefinedAdsetInsights({ commit }, req_body) {
        return api.facebook
            .getRefinedAdsetInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_age_adset_insights = response;
                commit("setRefinedAdsetInsights", payload);
            });
    },

    // Ads
    getFacebookAds({ commit }, ids) {
        return api.facebook.getFacebookAds(ids).then((response) => {
            const payload = {};
            payload.facebook_ads = response;
            commit("setFacebookAds", payload);
        });
    },
    getFacebookAdsByAdset({ commit }, ids) {
        return api.facebook.getFacebookAdsetAds(ids).then((response) => {
            const payload = {};
            payload.facebook_adset_ads = response;
            commit("setFacebookAdsetAds", payload);
        });
    },
    getFacebookAd({ commit }, ids) {
        return api.facebook.getFacebookAd(ids).then((response) => {
            const payload = {};
            payload.facebook_ad = response;
            commit("setFacebookAd", payload);
        });
    },
    getFacebookAdInsights({ commit }, req_body) {
        return api.facebook.getFacebookAdInsights(req_body).then((response) => {
            const payload = {};
            payload.facebook_ad_insights = response[0];
            commit("setAdInsights", payload);
        });
    },
    getTimeBasedCampaignInsights({ commit }, req_body) {
        return api.facebook
            .getTimeBasedAdsetInsights(req_body)
            .then((response) => {
                const payload = {};
                payload.facebook_time_based_adset_insights = response;
                commit("setTimeBasedAdsetInsights", payload);
            });
    },

    editFacebookAd({ commit }, req_body) {
        return api.facebook.editFacebookAd(req_body).then((response) => {
            const payload = {};
            payload.facebook_ad = response;
            commit("setFacebookAd", payload);
        });
    },
    deleteFacebookAd({ commit }, ids) {
        return api.facebook.deleteFacebookAd(ids).then((response) => {
            const payload = {};
            payload.facebook_ad = response;
            commit("setFacebookAd", payload);
        });
    },
    getFacebookCreative({ commit }, ids) {
        return api.facebook.getSingleCreative(ids).then((response) => {
            const payload = {};
            payload.facebook_creative = response;
            commit("setSingleCreative", payload);
        });
    },

    postAd({ commit }, data) {
        return api.facebook.postFacebookAd(data).then(response => {
            const payload = {};
            payload.facebook_ad = response;
            commit("setFacebookAd", payload);
        })
    },

    // Ad Preview
    postPreviewOption({ commit }, data) {
        return api.facebook.postPreviewOption(data).then(response => {
            const payload = {};
            payload.facebook_post_preview = response.body;
            commit("setPostPreview", payload)
        })
    },


    // Ad Images
    getAdImages({ commit }, ad_account_id) {
        return api.facebook.getAdImages(ad_account_id).then(response => {
            const payload = {};
            payload.ad_images = response;

            commit("setAdImages", payload);
        })
    },
    getAdImage({ commit }, req_body) {
        return api.facebook.getAdImage(req_body).then(response => {
            const payload = {};
            payload.ad_image = response;
            commit("setAdImage", payload);
        })
    },
    postAdImages({ commit }, body) {
        return api.facebook.uploadAdImages(body).then(response => {
            const payload = {};
            payload.ad_image = response;
            commit("pushToAdImagesArray", payload);
        })
    },

    //Ad Videos
    getAdVideo({ commit }, ids) {
        return api.facebook.getAdVideo(ids).then(response => {
            const payload = {};
            payload.ad_video = response.source;
            commit("setAdVideo", payload);
        })
    },
    postAdVideo({ commit }, video_data) {
        return api.facebook.postAdVideo(video_data).then(response => {
            const payload = {};
            payload.ad_video = response.id;
            commit("setAdVideo", payload);
        })
    },

    // Ad Creative
    postFacebookAdCreative({ commit }, data) {
        return api.facebook.postAdCreative(data).then((response) => {
            const payload = {}
            payload.facebook_ad_creative = response;
            commit("setAdCreative", payload);
        })
    },
    getAdCreatives({ commit }, ids) {
        return api.facebook.getAdCreatives(ids).then((response) => {
            const payload = {};
            payload.facebook_ad_creatives = response;
            commit("setAdCreatives", payload);
        });
    },

    // Client Pages
    getClientPages({ commit }) {
        return api.facebook.getClientPages().then((response) => {
            const payload = {};
            payload.facebook_client_pages = response;
            commit("setClientPages", payload);
        })
    },

    //Get Custom Audiences
    getCustomAudiences({ commit }, adAccountId) {
        return api.facebook.getAllCustomAudiences(adAccountId).then((response) => {
            const payload = {};
            payload.custom_audiences = response;
            commit("SetAdAccountCustomAudiences", payload);
        })
    },

    //Suggestions for Audience Targeting
    getAllLocations({ commit }, body) {
        return api.facebook.getAllCountries(body).then((response) => {
            const payload = {};
            payload.all_countries = response;
            commit("SetAllCountries", payload);
        })
    },

    getAllLanguages({ commit }, bodylanguage) {
        return api.facebook.getLanguages(bodylanguage).then((response) => {
            const payload = {};
            payload.all_languages = response;
            commit("SetAllLanguages", payload);
        })
    },


    getAllRegions({ commit }, bodyregions) {
        return api.facebook.getRegions(bodyregions).then((response) => {
            const payload = {};
            payload.all_regions = response;
            commit("SetRegions", payload);
        })
    },

    getAllCities({ commit }, bodycities) {
        return api.facebook.getCities(bodycities).then((response) => {
            const payload = {};
            payload.all_cities = response;
            commit("SetCities", payload);
        })
    },

    getZips({ commit }, bodyZips) {
        return api.facebook.getZipCodes(bodyZips).then((response) => {
            const payload = {};
            payload.zip_codes = response;
            commit("SetZips", payload)
        })
    },

    getaudienceInterest({ commit }, interestsBody) {
        return api.facebook.getInterests(interestsBody).then((response) => {
            const payload = {};
            payload.available_interests = response;
            commit("SetInterest", payload)
        })
    },
    getaudienceBehaviors({ commit }, behaviorsBody) {
        return api.facebook.getBehaviors(behaviorsBody).then((response) => {
            const payload = {};
            payload.available_behaviors = response;
            commit("SetBehaviors", payload)
        })
    },

    getAllEmployers({ commit }, work_params) {
        return api.facebook.getEmployers(work_params).then((response) => {
            const payload = {};
            payload.employers = response;
            commit("SetEmployers", payload)
        })
    },
    getAllDemographics({ commit }, demo_params) {
        return api.facebook.getDemographics(demo_params).then((response) => {
            const payload = {};
            payload.available_demographics = response;
            commit("SetDemographics", payload)
        })
    },
    getAllLifeEvents({ commit }, lifeParams) {
        return api.facebook.getLifeEvents(lifeParams).then((response) => {
            const payload = {};
            payload.life_events = response;
            commit("SetLifeEvents", payload)
        })
    },
    getAllIndustries({ commit }, industryparams) {
        return api.facebook.getIndustries(industryparams).then((response) => {
            const payload = {};
            payload.industries = response;
            commit("SetIndustries", payload)
        })
    },
    getAllIncome({ commit }, income_params) {
        return api.facebook.getIncome(income_params).then((response) => {
            const payload = {};
            payload.income = response;
            commit("SetIncome", payload)
        })
    },
    getAllEducationMajors({ commit }, majors_params) {
        return api.facebook.getMajors(majors_params).then((response) => {
            const payload = {};
            payload.all_majors = response;
            commit("SetEducationMajors", payload)
        })
    },
    getAllJobTitles({ commit }, job_titles_params) {
        return api.facebook.getJobTitles(job_titles_params).then((response) => {
            const payload = {};
            payload.job_titles = response;
            commit("SetJobTitles", payload)
        })
    },
    getAllUserOs({ commit }, user_os_params) {
        return api.facebook.getUserOs(user_os_params).then((response) => {
            const payload = {};
            payload.user_os = response;
            commit("SetUserOS", payload)
        })
    },
    // Landing Page Reports
    getFacebookReports({ commit }, group_by) {
        return api.facebook.getFacebookReports(group_by).then(response => {
            const payload = {};
            if (group_by === 'age') {
                payload.general_facebook_age_report = response;
                commit("setGeneralFacebookAgeReport", payload);
                return;
            }

            payload.general_facebook_reports = response;
            commit("setFacebookGeneralReports", payload);
        })
    },
    getCardsInsights({ commit }) {
        return api.facebook.getFacebookCardsInsights().then(response => {
            const payload = {};
            payload.facebook_cards_insights = response;
            commit("setCardsInsights", payload);
        })
    },
    // getReachByAgeReports({ commit }, group_by) {
    //   return api.facebook.getFacebookReports(group_by).then(response => {
    //     const payload = {};
    //     payload.reach_by_age_report = response;
    //     commit("setReachByAgeReport", payload);
    //   })
    // },

    getFacebookTodayInsights({ commit }) {
        return api.facebook.getTodayInsights().then(response => {
            const payload = {};
            payload.facebook_today_insights = response;
            commit("setTodayCardsInsights", payload);
        })
    },
}