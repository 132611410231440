class Users {
    constructor(client) {
        this.client = client;
    }
    // Agencies
    createAgency(body) {
        return this.client.post(`/agencies/`, body);
    }
    readAgency() {
        return this.client.get(`/agencies/`);
    }
    readOneAgency(id) {
        return this.client.get(`/agencies/${id}`);
    }
    updateAgency({ body, id }) {
        return this.client.post(`/agencies/edit/${id}`, body);
    }
    deleteAgency(id) {
        return this.client.delete(`/agencies/${id}`);
    }

    readPaginatedAgencies(value) {
        return this.client.get(`/agencies?page=${value}`);
    }

    readAllAgencies(value) {
        return this.client.get(`/agencies?per_page=${value}`);
    }

    sendUserInvitation(body) {
        return this.client.post(`/users/invite`, body);
    }

    //accept invitation
    acceptInvitation(body) {
        return this.client.post(`/auth/register/invitation`, body);
    }

    //Clients
    readManyClients() {
        return this.client.get("/clients/");
    }
    readOneClient(id) {
        return this.client.get(`/clients/${id}`)
    }
    createClient(body) {
        return this.client.post(`/clients/`, body);
    }
    updateClient({ body, id }) {
        return this.client.post(`/clients/edit/${id}`, body);
    }
    deleteClient(id) {
        return this.client.delete(`/clients/${id}`);
    }


    //Permissions
    readPermissions() {
        return this.client.get("/permissions/");
    }
    readOnePermission(id) {
        return this.client.get(`/permissions/${id}`);
    }
    updatePermission({ body, permission_id }) {
        return this.client.post(`/permissions/edit/${permission_id}`, body);
    }
    createPermission(body) {
        return this.client.post(`/permissions/`, body);
    }
    deletePermission(id) {
        return this.client.delete(`/permissions/${id}`);
    }

    //Roles
    readRoles() {
        return this.client.get("/roles");
    }
    readOneRole(id) {
        return this.client.get(`/roles/${id}`);
    }
    updateRole({ body, role_id }) {
        return this.client.post(`/roles/edit/${role_id}`, body);
    }
    createRole(body) {
        return this.client.post(`/roles/`, body);
    }
    deleteRole(id) {
        return this.client.delete(`/roles/${id}`);
    }

    //Users
    readUsers() {
        return this.client.get("/users");
    }
    readPaginatedUsers(value) {
        return this.client.get(`/users?page=${value}`);
    }
    deleteUser(id) {
        return this.client.delete(`/users/${id}`);
    }
    createUser(body) {
        return this.client.post(`/users/`, body);
    }
    readOneUser(id) {
        return this.client.get(`/users/${id}`);
    }
    updateUser({ body, user_id }) {
        return this.client.post(`/users/edit/${user_id}`, body);
    }

    //Update auth user profile and password
    updateAuthenticatedUserProfile(body) {
        return this.client.post(`/user/profile`, body);
    }

    updateAuthenticatedUserPassword(body) {
        return this.client.post(`/user/password`, body);
    }

    //user notifications
    readNotifications(body) {
        return this.client.get(`user/notifications`, body);
    }

    markNotificationAsRead() {
        return this.client.get(`user/markasread`);
    }

}

export default Users;