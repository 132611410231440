import init_state from "./state.js";

export default {
    resetFacebookState(state) {
        Object.assign(state, init_state)
    },
    setAdAccountId(state, payload) {
        sessionStorage.setItem("adAccountId", payload.ad_account_id);
        state.ad_account_id = payload.ad_account_id;
    },
    setFacebookAdaccounts(state, payload) {
        state.ad_accounts = payload.ad_accounts;
    },
    setAdaccountReportStatus(state, payload) {
        state.adaccount_report_status = payload.adaccount_report_status;
    },
    setFacebookAdaccountInsights(state, payload) {
        state.facebook_adaccount_insights = payload.facebook_adaccount_insights;
    },
    setAdaccountReportRun(state, payload) {
        state.adaccount_report_run_id = payload.adaccount_report_run_id;
    },
    setFacebookCampaigns(state, payload) {
        state.facebook_campaigns = payload.facebook_campaigns;
    },
    setMediapalSocialCampaigns(state, payload) {
        state.mediapal_social_campaigns = payload.mediapal_social_campaigns;
    },
    setFacebookCampaign(state, payload) {
        state.facebook_campaign = payload.facebook_campaign;
    },
    setFacebookCampaignInsights(state, payload) {
        state.facebook_campaign_insights = payload.facebook_campaign_insights;
    },
    setCombinedCampaignInsights(state, payload) {
        state.facebook_combined_campaign_insights = payload.facebook_combined_campaign_insights;
    },
    setTimedCampaignInsights(state, payload) {
        state.facebook_time_based_campaign_insights =
            payload.facebook_time_based_campaign_insights;
    },
    setFacebookAdsetsByAccount(state, payload) {
        state.facebook_adsets_by_account = payload.facebook_adsets_by_account;
    },
    setFacebookAdsetsByCampaign(state, payload) {
        state.facebook_adsets_by_campaign = payload.facebook_adsets_by_campaign;
    },
    setUpdatedFacebookAdset(state, payload) {
        state.update_facebook_adset = payload.update_facebook_adset;
    },
    setDeletedFacebookAdset(state, payload) {
        state.deleted_facebook_adset = payload.deleted_facebook_adset;
    },
    setFacebookAdsetInsights(state, payload) {
        state.facebook_adset_insights = payload.facebook_adset_insights;
    },
    setFacebookAdset(state, payload) {
        state.facebook_single_adset = payload.facebook_single_adset;
    },
    setFacebookAds(state, payload) {
        state.facebook_ads = payload.facebook_ads;
    },
    setAdInsights(state, payload) {
        state.facebook_ad_insights = payload.facebook_ad_insights;
    },
    setAdaccount(state, payload) {
        state.ad_account = payload.ad_account;
    },
    setFacebookAdsetAds(state, payload) {
        state.facebook_adset_ads = payload.facebook_adset_ads;
    },
    setAdCreative(state, payload) {
        state.facebook_ad_creative = payload.facebook_ad_creative;
    },
    setFacebookAd(state, payload) {
        state.facebook_ad = payload.facebook_ad;
    },
    setTimeBasedAdsetInsights(state, payload) {
        state.facebook_time_based_adset_insights =
            payload.facebook_time_based_adset_insights;
    },
    setRefinedAdsetInsights(state, payload) {
        state.facebook_age_adset_insights = payload.facebook_age_adset_insights;
    },
    setAdCreatives(state, payload) {
        state.facebook_ad_creatives = payload.facebook_ad_creatives;
    },
    setFacebookAdInsights(state, payload) {
        state.facebook_ad_insights = payload.facebook_ad_insights;
    },
    setSingleAdaccount(state, payload) {
        state.single_ad_account = payload.single_ad_account;
    },
    setSingleCreative(state, payload) {
        state.facebook_creative = payload.facebook_creative;
    },
    setClientPages(state, payload) {
        state.facebook_client_pages = payload.facebook_client_pages;
    },
    setAdImages(state, payload) {
        state.ad_images = payload.ad_images;
    },
    setAdImage(state, payload) {
        state.ad_image = payload.ad_image;
    },
    setAdVideo(state, payload) {
        state.ad_video = payload.ad_video;
    },
    pushToAdImagesArray(state, payload) {
        state.ad_images.push(payload.ad_image);
    },
    SetAdAccountCustomAudiences(state, payload) {
        state.custom_audiences = payload.custom_audiences;
    },
    SetAllCountries(state, payload) {
        state.all_countries = payload.all_countries;
    },
    SetRegions(state, payload) {
        state.all_regions = payload.all_regions;
    },
    SetCities(state, payload) {
        state.all_cities = payload.all_cities;
    },
    SetZips(state, payload) {
        state.zip_codes = payload.zip_codes;
    },
    SetAllLanguages(state, payload) {
        state.all_languages = payload.all_languages;
    },
    SetElectoralDistricts(state, payload) {
        state.electoral_districts = payload.electoral_districts;
    },
    SetSpecificLocation(state, payload) {
        state.specific_location = payload.specific_location;
    },
    SetInterest(state, payload) {
        state.available_interests = payload.available_interests;
    },
    SetBehaviors(state, payload) {
        state.available_behaviors = payload.available_behaviors;
    },
    SetEmployers(state, payload) {
        state.employers = payload.employers;
    },
    SetLifeEvents(state, payload) {
        state.life_events = payload.life_events;
    },
    setAgeCampaignInsights(state, payload) {
        state.facebook_age_based_campaign_insights = payload.facebook_age_based_campaign_insights;
    },
    setPostPreview(state, payload) {
        state.facebook_post_preview = payload.facebook_post_preview;
    },
    SetIndustries(state, payload) {
        state.industries = payload.industries;
    },
    SetIncome(state, payload) {
        state.income = payload.income;
    },
    SetEducationMajors(state, payload) {
        state.all_majors = payload.all_majors;
    },
    SetJobTitles(state, payload) {
        state.job_titles = payload.job_titles;
    },
    SetUserOS(state, payload) {
        state.user_os = payload.user_os;
    },

    setUpdatedFacebookCampaign(state, payload) {
        state.updated_facebook_campaign = payload.updated_facebook_campaign;
    },
    // Landing Page Reports
    setFacebookGeneralReports(state, payload) {
        state.general_facebook_reports = payload.general_facebook_reports;
    },
    setGeneralFacebookAgeReport(state, payload) {
        state.general_facebook_age_report = payload.general_facebook_age_report;
    },
    setCardsInsights(state, payload) {
        state.facebook_cards_insights = payload.facebook_cards_insights;
    },
    setSpecialError(state, payload) {
        state.special_error = payload.special_error;
    },
    setTodayCardsInsights(state, payload) {
        state.facebook_today_insights = payload.facebook_today_insights;
    },
    setUpdatedFacebookCampaignFees(state, payload) {
        state.updated_facebook_campaign_fees = payload.updated_facebook_campaign_fees;
    },
}