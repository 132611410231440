import init_state from "./state.js";

export default {
    resetGeneralState(state) {
        Object.assign(state, init_state)
    },
    makingApiRequest(state, payload) {
        state.isLoading = payload;
    },
    setUserAndToken(state, payload) {
        if (localStorage.getItem("access_token") === null) return;
        state.auth.access_token = localStorage.getItem("access_token");
        localStorage.setItem("auth_email", payload.user[0].email);
        localStorage.setItem("auth_user_id", payload.user[0].id);
        localStorage.setItem("auth_name", payload.user[0].name);
        localStorage.setItem("auth_roles", JSON.stringify(payload.user[0].roles));
        localStorage.setItem("auth_user_logo", JSON.stringify(payload.user[0].user_logo));
        state.auth.email = localStorage.getItem("auth_email");
        state.auth.user_id = localStorage.getItem("auth_user_id");
        state.auth.name = localStorage.getItem("auth_name");
        state.auth.roles = localStorage.getItem("auth_roles");
        state.auth.user_logo = localStorage.getItem("auth_user_logo");
    },
    setUserAndTokenOnRefresh(state) { // get values from local storage
        if (localStorage.getItem("access_token") === null) return;
        state.auth.access_token = localStorage.getItem("access_token");
        state.auth.email = localStorage.getItem("auth_email");
        state.auth.user_id = localStorage.getItem("auth_user_id");
        state.auth.name = localStorage.getItem("auth_name");
        state.auth.roles = localStorage.getItem("auth_roles");
        state.auth.user_logo = localStorage.getItem("auth_user_logo");

    },
    clearAuthUser(state) {
        window.localStorage.clear();
        state.auth.access_token = null;
        state.auth.user_id = null;
        state.auth.email = null;
        state.auth.name = null;
        state.auth.user_logo = null;
    },

    //Clients
    setNewClient(state, payload) {
        state.client = payload.client;
    },
    setClients(state, payload) {
        state.clients = payload.clients;
    },
    setOneClient(state, payload) {
        state.client = payload.client;
    },
    setUpdateClient(state, payload) {
        state.update_client = payload.update_client;
    },

    //Users
    setInvitation(state, payload) {
        state.invitation = payload.invitation;
    },

    //Agencies
    setNewAgency(state, payload) {
        state.agency = payload.agency;
    },
    setAgencies(state, payload) {
        state.agencies = payload.agencies;
    },
    setAllAgencies(state, payload) {
        state.all_agencies = payload.all_agencies;
    },

    setUpdateAgency(state, payload) {
        state.update_agency = payload.updateagency;
    },
    setOneAgency(state, payload) {
        state.single_agency = payload.single_agency;
    },
    setDeleteAgency(state, payload) {
        state.delete_agency = payload.delete_agency;
    },

    //Permissions
    setPermissions(state, payload) {
        state.permissions = payload.permissions;
    },
    setSinglePermission(state, payload) {
        state.permission = payload.permission;
    },
    setUpdatedPermission(state, payload) {
        state.updated_permission = payload.updated_permission;
    },
    setNewPermission(state, payload) {
        state.permission = payload.permission;
    },
    setDeletedPermission(state, payload) {
        state.deleted_permission = payload.deleted_permission;
    },

    //Roles
    setRoles(state, payload) {
        state.roles = payload.roles;
    },
    setSingleRole(state, payload) {
        state.role = payload.role;
    },
    setUpdatedRole(state, payload) {
        state.updated_role = payload.updated_role;
    },
    setNewRole(state, payload) {
        state.role = payload.role;
    },
    setDeletedRole(state, payload) {
        state.deleted_role = payload.deleted_role;
    },
    //Users
    setUsers(state, payload) {
        state.users = payload.users;
    },
    setDeletedUser(state, payload) {
        state.deleted_user = payload.deleted_user;
    },
    setNewUser(state, payload) {
        state.user = payload.user;
    },
    setSingleUser(state, payload) {
        state.user = payload.user;
    },
    setUpdatedUser(state, payload) {
        state.updated_user = payload.updated_user;
    },

    setAuthenticated(state, value) {
        state.authenticated = value;
    },

    setAuthenticatedUser(state, value) {
        state.authenticated_user = value;
    },

    setValidationErrors(state, value) {
        state.errors = value;
    },

    setNotifications(state, value) {
        state.notifications = value;
    },

    //Billing
    setQBCustomers(state, payload) {
        state.qb_customers = payload.qb_customers;
      },
}