class Auth {
  constructor(client) {
    this.client = client;
  }

  login(data) {
    return this.client.post(`auth/login`, data);
  }
  logout() {
    return this.client.post(`auth/logout`);
  }

  signup(data) {
    return this.client.post("backend-path", data);
  }
}

export default Auth;
