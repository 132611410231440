<template>
    <v-card :loading="loading" height="420" id="container" flat>
        <v-card-title class="d-flex align-center py-1">
            <h5 class="mr-auto">Active Campaigns</h5>
        </v-card-title>

        <v-divider></v-divider>
        <apexchart type="pie" height="370" :options="donutOptions" :series="donutData"></apexchart>
    </v-card>
</template>

<script>

export default {
    data() {
        return {
            size: "30m",
            interval: 10,
            loading: false,
            donutData: [],
            donutOptions: {
                chart: {
                    type: 'pie',
                },
                noData: {
                    text: "No Data Available 😐",
                    align: "center",
                    verticalAlign: "middle",
                },
                labels: [],
                colors: ['#77c1ab', '#6092c0', '#d36085', '#ffb01a', '#00E396', '#80c7fd', '#008FFB', '#80f1cb', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                }
            },
        }
    },
    //props
    props: {
        color: {
            type: String,
            default: "#80CBC4"
        },
        data: {
            type: Array,
            default: () => []
        }
    },

    watch: {
        active_campaigns: {
            handler: function (val) {

                //get the categories as key from val array
                const keysArray = val.map(bucket => bucket.key);
                const countsArray = val.map(bucket => bucket.doc_count);

                //set that to series
                this.donutData = countsArray;

                //set that to chart options
                this.donutOptions = {
                    ...this.donutOptions,
                    labels: keysArray,
                };
            },
            deep: true
        }
    },

    mounted() {
        this.fetchActiveBidderCampaigns();
    },

    computed: {
        active_campaigns() {
            return this.$store.state.dsp.active_bidder_campaigns;
        }
    },

    methods: {
        async fetchActiveBidderCampaigns() {

            let filters = {
                interval: this.interval,
                size: this.size,
            };

            this.loading = true;

            try {

                await this.$store.dispatch("dsp/getActiveBidderCampaigns", filters);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>


<style scoped>
#container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
}

.card-title {
    flex-wrap: nowrap !important;
    display: block !important;
}
</style>
