<template>
  <div class="mt-3">
    <v-row>
      <v-col v-if="!_isSafaricom" cols="3">
        <v-select
          v-model="selectedRoute"
          :items="routes"
          @change="goToRoute"
          label="Select Dashboard"
          :hide-selected="true"
          outlined
          dense
          hide-details
          class="shrink"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-select
      ></v-col>
    </v-row>

    <router-view :key="$router.path"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // toggle_exclusive: undefined,
      selectedRoute: "/",
      routes: [
        { text: "Programmatic Overview", value: "/" },
        { text: "Facebook Overview", value: "/home/facebook" },
        { text: "Opera Overview", value: "/home/opera" },
        { text: "Transsion Overview", value: "/home/transsion" },
        { text: "TrueCaller Overview", value: "/home/truecaller" },
        { text: "Phoenix Overview", value: "/home/phoenix" },
      ],
    };
  },
  methods: {
    goToRoute() {
      this.$router.push({ path: this.selectedRoute });
    },
  },
  mounted() {
    this.selectedRoute = this.$route.path;
  },
  computed: {
    _isSafaricom() {
      return location.hostname == "safaricom.mediapal.net"  ? true : false;
    }
  }
};
</script>
