import api from "../api";

export default {
  //Reset State 
  resetGeneralState({ commit }) {
    commit("resetGeneralState")
  },
  // Auth
  signup({ commit }, user) {
    return api.auth.login(user).then((response) => {
      localStorage.setItem("access_token", response.data.access_token);
      commit("setUserAndToken", response.data);
    });
  },
  login({ commit }, user) {
    return api.auth.login(user).then((response) => {
      localStorage.setItem("access_token", response.access_token);
      const user = response.user;
      commit("setUserAndToken", response);
      commit("setAuthenticatedUser", user[0]);
      commit("setAuthenticated", true);
    });
  },
  logout({ commit }) {
    return api.auth.logout().then(() => {
      commit("clearAuthUser");
      commit("setAuthenticatedUser", {});
      commit("setAuthenticated", false);
    });
  },

  // Clients
  addNewClient({ commit }, body) {
    return api.users.createClient(body).then(response => {
      const payload = {};
      payload.create_client = response;
      commit("setNewClient", payload);
    })
  },
  readClients({ commit }) {
    return api.users.readManyClients().then(response => {
      const payload = {};
      payload.clients = response;
      commit("setClients", payload);
    })
  },
  readClient({ commit }, id) {
    return api.users.readOneClient(id).then(response => {
      const payload = {};
      payload.client = response;
      commit("setOneClient", payload);
    })
  },
  EditClient({ commit }, req_body) {
    return api.users.updateClient(req_body).then(response => {
      const payload = {};
      payload.update_client = response;
      commit("setUpdateClient", payload);
    })
  },
  delClient({ commit }, id) {
    return api.users.deleteClient(id).then(response => {
      const payload = {};
      payload.client = response;
      commit("setOneClient", payload);
    })
  },

  // Agencies
  addNewAgency({ commit }, body) {
    return api.users.createAgency(body).then(response => {
      const payload = {};
      payload.agency = response;
      commit("setNewAgency", payload);
    })
  },
  readAgencies({ commit }) {
    return api.users.readAgency().then(response => {
      const payload = {};
      payload.agencies = response;
      commit("setAgencies", payload);
    })
  },

  updateAgency({ commit }, req_body) {
    return api.users.updateAgency(req_body).then(response => {
      const payload = {};
      payload.update_agency = response;
      commit("setUpdateAgency", payload);
    })
  },
  readAgency({ commit }, id) {
    return api.users.readOneAgency(id).then(response => {
      const payload = {};
      payload.single_agency = response;
      commit("setOneAgency", payload);
    })
  },
  deleteAgency({ commit }, id) {
    return api.users.deleteAgency(id).then(response => {
      const payload = {};
      payload.delete_agency = response;
      commit("setDeleteAgency", payload);
    })
  },
  readPaginatedAgencies({ commit }, value) {
    return api.users.readPaginatedAgencies(value).then(response => {
      const payload = {};
      payload.agencies = response;
      commit("setAgencies", payload);
    })
  },
  readAllAgencies({ commit }, value) {
    return api.users.readAllAgencies(value).then(response => {
      const payload = {};
      payload.all_agencies = response.data;
      commit("setAllAgencies", payload);
    })
  },
  //Permissions
  readPermissions({ commit }) {
    return api.users.readPermissions().then(response => {
      const payload = {};
      payload.permissions = response;
      commit("setPermissions", payload);
    })
  },
  getSinglePermission({ commit }, id) {
    return api.users.readOnePermission(id).then((response) => {
      const payload = {};
      payload.permission = response;
      commit("setSinglePermission", payload);
    });
  },
  updatePermission({ commit }, req_body) {
    return api.users.updatePermission(req_body).then((response) => {
      const payload = {};
      payload.updated_permission = response;
      commit("setUpdatedPermission", payload);
    });
  },
  addNewPermission({ commit }, body) {
    return api.users.createPermission(body).then(response => {
      const payload = {};
      payload.permission = response;
      commit("setNewPermission", payload);
    })
  },
  deletePermission({ commit }, body) {
    return api.users.deletePermission(body).then((response) => {
      const payload = {};
      payload.deleted_permission = response;
      commit("setDeletedPermission", payload);
    });
  },
  //Roles
  readRoles({ commit }) {
    return api.users.readRoles().then(response => {
      const payload = {};
      payload.roles = response;
      commit("setRoles", payload);
    })
  },
  getSingleRole({ commit }, id) {
    return api.users.readOneRole(id).then((response) => {
      const payload = {};
      payload.role = response;
      commit("setSingleRole", payload);
    });
  },
  updateRole({ commit }, req_body) {
    return api.users.updateRole(req_body).then((response) => {
      const payload = {};
      payload.updated_role = response;
      commit("setUpdatedRole", payload);
    });
  },
  addNewRole({ commit }, body) {
    return api.users.createRole(body).then(response => {
      const payload = {};
      payload.role = response;
      commit("setNewRole", payload);
    })
  },
  deleteRole({ commit }, body) {
    return api.users.deleteRole(body).then((response) => {
      const payload = {};
      payload.deleted_role = response;
      commit("setDeletedRole", payload);
    });
  },

  //Invite user
  sendUserInvitation({ commit }, body) {
    return api.users.sendUserInvitation(body).then(response => {
      const payload = {};
      payload.invitation = response;
      commit("setInvitation", payload);
    })
  },

  //accept invitation
  acceptInvitation({ commit }, body) {
    return api.users.acceptInvitation(body).then(response => {
      const payload = {};
      payload.invitation = response;
      commit("setInvitation", payload);
    })
  },

  //Users
  readUsers({ commit }) {
    return api.users.readUsers().then(response => {
      const payload = {};
      payload.users = response;
      commit("setUsers", payload);
    })
  },
  readPaginatedUsers({ commit }, value) {
    return api.users.readPaginatedUsers(value).then(response => {
      const payload = {};
      payload.users = response;
      commit("setUsers", payload);
    })
  },

  deleteUser({ commit }, body) {
    return api.users.deleteUser(body).then((response) => {
      const payload = {};
      payload.deleted_role = response;
      commit("setDeletedUser", payload);
    });
  },
  addNewUser({ commit }, body) {
    return api.users.createUser(body).then(response => {
      const payload = {};
      payload.user = response;
      commit("setNewUser", payload);
    })
  },
  getSingleUser({ commit }, id) {
    return api.users.readOneUser(id).then((response) => {
      const payload = {};
      payload.user = response;
      commit("setSingleUser", payload);
    });
  },
  updateUser({ commit }, req_body) {
    return api.users.updateUser(req_body).then((response) => {
      const payload = {};
      payload.updated_user = response;
      commit("setUpdatedUser", payload);
    });
  },

  //update authenticated user profile
  updateAuthenticatedUserProfile({ commit }, body) {
    return api.users.updateAuthenticatedUserProfile(body).then((response) => {
      const user = response.data;
      commit("setAuthenticatedUser", user);
    });
  },

  //update authenticated user password
  updateAuthenticatedUserPassword({ commit }, body) {
    return api.users.updateAuthenticatedUserPassword(body).then((response) => {
      if (response.status == 'false') {
        commit("setValidationErrors", response.message)
      }
    });
  },

  //notifications
  getUserNotifications({ commit }, body) {
    return api.users.readNotifications(body).then((response) => {
      const payload = {};
      payload.notifications = response;
      commit("setNotifications", payload);
    });
  },


  //mark notification as read
  markNotificationAsRead({ commit }) {
    return api.users.markNotificationAsRead().then((response) => {
      const payload = {};
      payload.notifications = response;
      commit("setNotifications", payload);
    });
  },

  getQuickbooksCustomers({ commit }) {
    return api.billing.getQuickbooksCustomers().then((response) => {
      const payload = {};
      payload.qb_customers = response;
      commit("setQBCustomers", payload);
    });
  },
}