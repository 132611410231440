//apexcharts config colours
export default {
  light: {
    primary: "#1976d2",
    secondary: "#424242",
    error: "#ff5252",
    warning: "#fb8c00",
    success: "#4caf50",
    info: "#2196f3",
    textColor: "#B9B9B9",
    iconColor: "#FFFFFF59",
    grayBold: "#4a4a4a",
  },
};
