<template>
  <v-card flat id="campaign">
    <ConfirmDelete
      :dialog="dialog"
      :item="mine"
      @delete="rundeleteFn($event)"
      @dismiss="dismissFn($event)"
    />

    <!-- <showBanners
      :dialog="modalShow"
      :item="mine"
      @submitBanners="submitCampaignBanners($event)"
      @dismiss="dismissFn($event)"
    /> -->
    <change-log-banner-modal
      :dialog="changeDialog"
      :item="mine"
      v-if="superAdmin"
      @dismiss="dismissFn($event)"
    ></change-log-banner-modal>

    <v-card-title>
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <v-btn
              class="mr-2"
              @click="redirectToCreateCampaign"
              depressed
              color="primary"
            >
              <v-icon left> mdi-plus </v-icon>
              New Campaign
            </v-btn>
            <v-btn
              v-if="selected.length"
              @click="filterGraphByCampaign"
              depressed
              color="secondary"
            >
              <v-icon left> mdi-filter </v-icon>
              ({{ selected.length }}) Filter
            </v-btn>
            <v-btn
              v-if="selected.length"
              @click="resetCampaignsFilter"
              class="ml-2"
              color="grey"
              depressed
              dark
            >
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            class="shrink"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col>
          <v-select
            v-model="headersSelected"
            :items="headers"
            return-object
            dense
            outlined
            :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
            hide-details
            label="Select Columns"
            multiple
            append-icon="mdi-chevron-down"
            class="shrink"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip x-small v-if="index === 1">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ headersSelected.length - 1 }} others)
              </span>
            </template></v-select
          >
        </v-col>
      </v-row>

      <!-- <v-spacer></v-spacer> -->
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      v-model="selected"
      item-key="id"
      show-select
      :headers="headersSelected"
      :items="campaigns"
      :search="search"
      @click:row="viewReport"
      :loading="loading"
      loading-text="Loading... Please wait"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="green"
          :value="isSelected"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <span class="font-weight-bold">
          <router-link
            :to="{
              name: 'campaign',
              params: {
                id: item.id,
              },
            }"
          >
            {{ item.name }}
          </router-link>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.toggle_status`]="{ item }">
        <v-switch
          :input-value="formatRunnableStatus(item.status)"
          color="success"
          @change="toggleCampaignStatus(item.id)"
          :loading="toggleLoading[item.id] || false"
        ></v-switch>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.campaign_type`]="{ item }">
        <span class="text-capitalize">{{
          item.campaign_type ? item.campaign_type : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.campaign_objective`]="{ item }">
        <span class="text-capitalize">{{
          item.campaign_objective ? item.campaign_objective : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.bid_strategy`]="{ item }">
        <span class="text-capitalize">{{
          item.bid_strategy ? item.bid_strategy.toUpperCase() : "-"
        }}</span>
      </template>
      <template #[`item.pacing`]="{ item }">
        <v-menu open-on-hover :nudge-width="220" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="d-flex justify-space-between">
                <!-- <h4>{{ pacingPerformance(item) }}</h4> -->
                <span style="font-size: 9px">{{ checkValues(item) }}</span>
                <span style="font-size: 9px"
                  >{{ Math.ceil(pacingValue(item)) }}%</span
                >
              </div>
              <v-progress-linear
                :value="pacingValue(item)"
                rounded
                height="4"
                :color="pacingPerformance(item)"
              >
              </v-progress-linear>
              <div class="d-flex" style="position: relative">
                <div
                  id="marker"
                  :style="{ left: `${idealSpendProgress(item)}%` }"
                ></div>
              </div>
            </div>
          </template>

          <v-card>
            <v-card-text class="py-2">
              <v-row>
                <v-col>
                  <h5>Flight Pacing at {{ Math.ceil(pacingValue(item)) }}%</h5>
                </v-col>
                <v-col>
                  <h5>Budget of ${{ Number(item.budget).toFixed(2) }}</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="py-2">
              <v-row>
                <v-col>
                  <div class="d-flex align-center">
                    <v-progress-linear
                      :value="pacingValue(item)"
                      rounded
                      height="6"
                      :color="pacingPerformance(item)"
                      style="width: 25%"
                      class="mr-2"
                    >
                    </v-progress-linear>
                    <h5>Spend</h5>
                  </div>
                  <span class="overline">${{ spend(item) }}</span>
                </v-col>
                <v-col>
                  <h5>
                    <v-icon color="green">mdi-triangle-small-up</v-icon>Ideal
                    Spend
                  </h5>
                  <span class="overline">${{ idealSpend(item) }}</span>
                </v-col>
                <v-col>
                  <div class="d-flex align-center">
                    <v-progress-linear
                      :value="pacingBudgetValue(item)"
                      rounded
                      height="6"
                      style="width: 25%"
                      class="mr-2"
                      color="grey"
                    >
                    </v-progress-linear>
                    <h5>Remaining</h5>
                  </div>

                  <span class="overline">{{
                    parseFloat(checkBudgetRemaining(item)).toLocaleString(
                      "en-us",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.daily_pacing`]="{ item }">
        <v-menu open-on-hover :nudge-width="380" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="d-flex justify-space-between">
                <!-- <h4>{{ pacingPerformance(item) }}</h4> -->

                <span style="font-size: 9px">{{ checkDailyValues(item) }}</span>
                <span style="font-size: 9px"
                  >{{ Math.ceil(pacingDailyValue(item)) }}%</span
                >
              </div>
              <v-progress-linear
                :value="pacingDailyValue(item)"
                rounded
                height="4"
                :color="pacingDailyPerformance(item)"
              >
              </v-progress-linear>
            </div>
          </template>
          <v-card>
            <v-card-text class="py-2">
              <v-row>
                <v-col>
                  <h5>Pacing at {{ Math.ceil(pacingDailyValue(item)) }}%</h5>
                </v-col>
                <v-col>
                  <h5>Spend to Pace at ${{ item.spend_today.toFixed(2) }}</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="py-2">
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-progress-linear
                      :value="pacingSpendyesterdayValue(item)"
                      rounded
                      height="6"
                      :color="
                        simplePacingPerfomance(pacingSpendyesterdayValue(item))
                      "
                      style="width: 20%"
                      class="mr-2"
                    >
                    </v-progress-linear>
                    <h5>Spend Yesterday</h5>
                  </div>

                  <span class="overline">${{ item.spend_yesterday }}</span>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-progress-linear
                      :value="pacingDailyValue(item)"
                      rounded
                      height="6"
                      :color="pacingDailyPerformance(item)"
                      style="width: 20%"
                      class="mr-2"
                    >
                    </v-progress-linear>
                    <h5>Overspend</h5>
                  </div>

                  <span class="overline"
                    >${{ overspend(item).toFixed(2) }}</span
                  >
                </v-col>
                <v-col cols="4">
                  <h5>Daily Budget Limit</h5>
                  <span class="overline">{{
                    parseFloat(dailyBudgetLimit(item)).toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.activity_status="{ item }">
        <v-chip
          v-if="item.activity_status === null"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>Incomplete
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Active'"
          color="green lighten-5"
          text-color="success"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Paused'"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'In Review'"
          color="orange lighten-5"
          text-color="warning"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Completed'"
          color="cyan lighten-5"
          text-color="cyan"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else
          color="red lighten-5"
          text-color="error"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.campaign_type="{ item }">
        <span class="text-capitalize">{{ item.campaign_type }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.budget="{ item }">
        <span>${{ Number(item.budget).toFixed(2) }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.budget_remaining="{ item }">
        <span
          >{{
            parseFloat(checkBudgetRemaining(item)).toLocaleString("en-us", {
              style: "currency",
              currency: "USD",
            })
          }}
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.spend_yesterday="{ item }" v-if="superAdmin">
        <span>${{ item.spend_yesterday.toFixed() }}</span>
      </template>

      <!-- PROGRAMATIC -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.start_date="{ item }">
        <span>
          {{ new Date(item.start_date).toLocaleDateString("en-uk") }}</span
        >
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.end_date="{ item }">
        <span> {{ new Date(item.end_date).toLocaleDateString("en-uk") }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.days_left="{ item }">
        <span> {{ daysLeft(item.end_date) }}</span>
      </template>

      <!-- action -->
      <template #[`item.action`]="{ item }">
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="showBanners(item)">
              <v-list-item-title
                ><v-icon fab small class="me-2">mdi-image</v-icon>View
                Banners</v-list-item-title
              >
            </v-list-item> -->
            <v-list-item
              v-if="
                (currentPage && item.activity_status === 'Active') ||
                item.activity_status === 'Paused'
              "
              @click="showSnack(item)"
            >
              <v-list-item-title
                ><v-icon fab small class="me-2">{{
                  item.activity_status === "Active" ? "mdi-pause" : "mdi-play"
                }}</v-icon
                >{{
                  item.activity_status === "Active"
                    ? "Pause Campaign"
                    : "Activate Campaign"
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              @click="
                $router.push({
                  name: 'edit-dsp-campaign',
                  params: { id: item.id },
                })
              "
            >
              <v-list-item-title
                ><v-icon small class="me-2">mdi-pencil</v-icon> Edit
                Campaign</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="showLogs(item)" v-if="superAdmin">
              <v-list-item-title>
                <v-icon depressed small class="me-2"
                  >mdi-file-document-outline</v-icon
                >
                Change Log
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="currentPage" @click="openModal(item)">
              <v-list-item-title
                ><v-icon small color="error" class="me-2">mdi-delete</v-icon>
                Delete Campaign</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-snackbar color="primary" top v-model="snackbar">
      {{ snackText() }}

      <template v-slot:action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="updateCampaignStatus()"
        >
          Yes
        </v-btn>
      </template>
    </v-snackbar>
    <v-divider></v-divider>

    <v-card-actions>
      <div class="d-flex align-center">
        <div class="caption mx-3">Page: {{ page }} of {{ pageCount }}</div>

        <v-select
          v-model="itemsPerPage"
          outlined
          :items="itemsPerPageOptions"
          label="Rows Per Page"
          dense
          hide-details
        ></v-select>
      </div>

      <v-spacer></v-spacer>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="10"
        class="custom"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import ConfirmDelete from "../ConfirmDelete.vue"
// import showBanners from "../Programmatic/ShowCampaignBannersModal.vue";
import ChangeLogBannerModal from "../Programmatic/ChangeLogBannerModal.vue"
// import BillingMetaData from "../Programmatic/BillingMetaData.vue";

let date = new Date()
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  props: {
    campaigns: {
      type: Array,
    },
    loading: Boolean,
    headers: {
      type: Array,
    },
    create_campaign: {
      type: Object,
    },
  },
  components: {
    ConfirmDelete,
    // showBanners,
    ChangeLogBannerModal,
    // BillingMetaData,
  },
  data() {
    return {
      selected: [],
      dialog: false,
      changeDialog: false,
      billingDialog: false,
      snackbar: false,
      modalShow: false,
      mine: {},
      snack_campaign: {},
      sortBy: "start_date",
      sortDesc: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 15, 25, 50, 200, 250],
      deals_filters: ["Preferred Deal", "Private Auction"],
      deals_filter: "",
      youtube_filters: ["Ended", "Eligible"],
      youtube_filter: "",
      show: false,
      date: date,
      search: "",
      headersSelected: [],
      toggleLoading: {},
    }
  },
  methods: {
    async toggleCampaignStatus(id) {

      this.$set(this.toggleLoading, id, true)

      try {

        await this.$store.dispatch("dsp/manageCampaignBiddingStatus", id);

        this.$emit("filterParams", this.filter_params)
        
      } catch (error) {
        console.log(error)
      } finally {
        this.$set(this.toggleLoading, id, false)
      }
    },
    formatRunnableStatus(status) {
      if (status === "runnable") {
        return true
      } else {
        return false
      }
    },
    filterGraphByCampaign() {
      this.$emit("filterGraphByCampaign", this.__selectedCampaigns)
    },
    resetCampaignsFilter() {
      this.selected = []
      this.$emit("resetCampaignsFilter")
    },
    handleSearchChange() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.$emit("filterBySearch", this.search)
      }, 500)
    },
    populateHeaders() {
      this.headersSelected = this.headers
    },
    spend(item) {
      let budget = item.budget ? item.budget : 0
      let spend = item.spend ? item.spend : 0
      budget = Number(budget)
      if (!this.superAdmin && spend > budget) {
        spend = budget
      }
      return spend.toFixed(2)
    },
    pacingValue(item) {
      let budget = item.budget ? item.budget : 0
      let spend = this.spend(item)
      let convertedBudget = budget
      let convertedSpend = spend

      let value = (Number(convertedSpend) / Number(convertedBudget)) * 100

      if (!this.superAdmin && value > 100) {
        value = 100
      } else if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value.toFixed(2)
    },
    pacingPerformance(item) {
      //get todays date
      let today = new Date()
      today = new Date(
        today.getMonth() +
          1 +
          " / " +
          today.getDate() +
          "/" +
          today.getFullYear()
      )

      let startDate = new Date(item.start_date)

      let endDate = new Date(item.end_date)

      let days =
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

      let dailyBudget = parseInt(item.budget) / days

      let runDays

      if (today.getTime() < endDate.getTime()) {
        runDays =
          (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      } else {
        runDays =
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      }
      let expectedBudget = runDays * dailyBudget

      let perfomance = ((this.spend(item) / expectedBudget) * 100).toFixed(2)

      let days_left = this.daysLeft(item.end_date)

      let perfomancedays = ((days_left / days) * 100).toFixed(0)
      // return `${perfomancedays} / ${perfomance}`;

      if (perfomance < 50 && perfomancedays > 75) {
        return "grey"
      } else if (perfomance >= 50 && perfomance < 90 && perfomancedays < 80) {
        return "warning"
      } else if (perfomance >= 90) {
        return "success"
      } else return "error"
    },
    idealSpend(item) {
      let startDate = new Date(item.start_date)

      let endDate = new Date(item.end_date)

      let today = new Date()
      today = new Date(
        today.getMonth() +
          1 +
          " / " +
          today.getDate() +
          "/" +
          today.getFullYear()
      )
      if (today < endDate) {
        // if campaing hasn't reach end date calculate ideal spend with current date
        let runDays =
          (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

        return (runDays * item.daily_spend_limit).toFixed(2)
      } else {
        let runDays =
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
        return (runDays * item.daily_spend_limit).toFixed(2)
      }
    },
    idealSpendProgress(item) {
      let progress = (
        (this.idealSpend(item) / Number(item.budget)) *
        100
      ).toFixed(0)
      if (progress > 100) {
        return (progress = 100)
      } else if (progress < 0) {
        return (progress = 0)
      } else return progress
    },
    simplePacingPerfomance(item) {
      if (item > 100) {
        return "error"
      } else return "success"
    },
    checkBudgetRemaining(item) {
      let budget = item.budget ? item.budget : 0
      let spend = this.spend(item)

      let convertedBudget = budget
      let convertedSpend = spend

      let remaining = Number(convertedBudget) - Number(convertedSpend)

      return remaining.toFixed(2)
    },
    pacingBudgetValue(item) {
      let budget = item.budget ? item.budget : 0
      let spend = this.spend(item)

      let convertedBudget = budget
      let convertedSpend = spend

      let remaining = Number(convertedBudget) - Number(convertedSpend)

      return ((remaining / convertedBudget) * 100).toFixed(2)
    },
    pacingSpendyesterdayValue(item) {
      let pacingValue = (
        (item.spend_yesterday / Number(item.daily_spend_limit)) *
        100
      ).toFixed(2)
      return pacingValue
    },

    checkValues(item) {
      let spend = this.spend(item)
      let budget = item.budget ? item.budget : 0
      let convertedSpend = spend
      let convertedBudget = budget

      return (
        Number(convertedSpend).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        }) +
        " / " +
        Number(convertedBudget).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        })
      )
    },
    checkDailyValues(item) {
      let spend = item.spend_today
      let budget = item.daily_spend_limit ? item.daily_spend_limit : 0
      let convertedSpend = spend
      let convertedBudget = budget

      return (
        Number(convertedSpend).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        }) +
        " / " +
        Number(convertedBudget).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        })
      )
    },
    pacingDailyValue(item) {
      let budget = item.daily_spend_limit ? item.daily_spend_limit : 0
      let spend = Number(item.spend_today)
      let convertedBudget = budget
      let convertedSpend = spend

      let value = (Number(convertedSpend) / Number(convertedBudget)) * 100

      if (!this.superAdmin && value > 100) {
        value = 100
      } else if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value.toFixed(2)
    },

    pacingDailyPerformance(item) {
      let perfomance = (
        (item.spend_today / item.daily_spend_limit) *
        100
      ).toFixed(0)
      if (!isFinite(perfomance) || isNaN(perfomance)) {
        perfomance = 0
      }
      if (perfomance > 33 && perfomance < 66) {
        return "warning"
      } else if (perfomance > 66) {
        return "success"
      } else return "error"
    },
    overspend(item) {
      let overspend = item.spend_today - item.daily_spend_limit
      if (overspend < 0) {
        return 0
      } else return overspend
    },
    dailyBudgetLimit(item) {
      let daily_spend_limit = item.daily_spend_limit
      if (daily_spend_limit) {
        return daily_spend_limit
      } else return 0
    },

    daysLeft(endDate) {
      //get todays date
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      let today = new Date()
      let end = new Date(endDate)

      const daysLeft = end.getTime() - today.getTime()
      return daysLeft > 0 ? Math.round(Math.abs(daysLeft / oneDay)) : 0
    },
    snackText() {
      if (this.snack_campaign.activity_status === "Active") {
        return "Are you sure you want to pause this campaign?"
      } else {
        return "Are you sure you want to activate this campaign?"
      }
    },
    updateCampaignStatus() {
      let status_name = ""
      if (this.snack_campaign.activity_status === "Active") {
        status_name = "Paused"
      } else if (this.snack_campaign.activity_status === "Paused") {
        status_name = "Active"
      }

      let body = {
        campaign_id: this.snack_campaign.id,
        req_body: {
          status_name: status_name,
        },
      }
      this.$emit("updateCampaignStatus", body)
      this.snackbar = false
    },
    showSnack(item) {
      this.snack_campaign = item
      this.snackbar = true
    },
    redirectToCreateCampaign() {
      this.$router.push({ name: this.create_campaign.create })
    },
    handlePageChange(value) {
      this.dsp_pagination.page = value
      this.filter_params.page = value
      this.$emit("triggerPageChange", value)
    },
    viewReport(item) {
      this.$emit("viewReport", item.id)
    },
    isDsp() {
      return this.$route.name === "campaigns"
    },
    async showBanners(item) {
      this.modalShow = !this.modalShow
      this.mine = await item
    },
    async showLogs(item) {
      this.changeDialog = !this.changeDialog
      this.mine = await item
    },
    submitCampaignBanners(campaign_id) {
      this.$emit("submitBanners", campaign_id)
    },
    rundeleteFn(id) {
      // Delete campaign
      this.$emit("deleteCampaign", id)
    },
    dismissFn() {
      this.dialog = false
      this.changeDialog = false
      this.modalShow = false

      this.mine = {}
    },
    async openModal(item) {
      this.dialog = !this.dialog
      // this.modalOpen = true;
      this.mine = await item
    },
    setStatusColor(status) {
      if (status === "WITH_ISSUES") return "error"
      if (status === "rejected") return "error"
      if (status === "suspended") return "error"
      if (status === "IN_PROCESS") return "warning"
      if (status === "waiting") return "warning"
      if (status === "PAUSED") return "warning"
      if (status === "ACTIVE") return "success"
      if (status === "running") return "success"
      if (status === "runnable") return "success"
      if (status === "ARCHIVED") return "secondary"
      if (status === "finished") return "secondary"
      if (status === "Eligible") return "success"
      if (status === "Ended") return "secondary"
      if (status === "offline") return "secondary"
    },
    setClass(status) {
      if (status === "WITH_ISSUES") return "error--text"
      if (status === "IN_PROCESS") return "warning--text"
      if (status === "PAUSED") return "warning--text"
      if (status === "ACTIVE") return "success--text"
      if (status === "ARCHIVED") return "success-text"
    },
    styleString(data) {
      return data.replaceAll("_", " ")
    },
    generate() {
      let docDefinition = {
        content: [
          {
            text: "CAMPAIGNS",
            fontSize: 20,
            bold: true,
            alignment: "center",
            decoration: "underline",
            color: "skyblue",
          },
          {
            text: "Disclaimer!",
            style: "sectionHeader",
          },
          {
            columns: [
              [{ text: "This is live data." }],
              [
                {
                  text: `Date: ${new Date().toLocaleString()}`,
                  alignment: "right",
                },
                {
                  text: `Serial : ${(Math.random() * 1000).toFixed(0)}`,
                  alignment: "right",
                },
              ],
            ],
          },
          {
            columns: [
              [
                {
                  qr: "Mediapal",
                  fit: "50",
                  style: "sectionHeader",
                },
              ],
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", "auto", "auto", "auto"],
              body: [
                ["Ad", "Status", "Objective", "Amount Remaining"],
                ...this.campaigns.map((c) => [
                  c.name,
                  c.effective_status,
                  c.objective,
                  c.budget_remaining,
                ]),
                [{ text: "Total Amount", colSpan: 3 }, {}, {}, {}],
              ],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: "underline",
            fontSize: 14,
            margin: [0, 15, 0, 15],
          },
        },
      }
      pdfMake.createPdf(docDefinition).print()
    },
  },
  async mounted() {
    this.$route.name == "facebook"
      ? (this.isFacebook = true)
      : this.$route.name == "direct-deals"
      ? (this.isDirectDeals = true)
      : this.$route.name == "youtube-campaigns"
      ? (this.isYoutube = true)
      : null

    this.$route.name == "home" ? (this.isHome = true) : null
    this.$route.name == "Facebook Dashboard" ? (this.isFacebookDb = true) : null
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    currency() {
      let data = this.$store.state.ad_account
      if (data) {
        return data.currency
      }
    },
    __selectedCampaigns() {
      return this.selected.map((campaign) => campaign.id)
    },
    superAdmin() {
      return this.$store.getters.superAdmin
    },

    currentPage() {
      return this.$route.name === "campaigns"
    },
  },
  watch: {
    selected_agency() {
      this.$emit("filterByAgency", this.selected_agency)
    },
    selected_advertiser() {
      this.$emit(
        "filterByAdvertiser",
        this.selected_advertiser ? this.selected_advertiser.id : ""
      )
    },
    filter_params: {
      handler: function () {
        this.itemsPerPage = this.filter_params.per_page
        this.$emit("filterParams", this.filter_params)
      },
      deep: true,
    },
  },
  created() {
    this.populateHeaders()
  },
}
</script>

<style scoped>
.custom {
  width: auto;
  justify-items: center;
}

.custom .v-pagination__navigation {
  height: 24px !important;
  width: 24px !important;
}

.custom .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}

.custom .v-pagination__item {
  height: 24px !important;
  min-width: 24px !important;
  font-size: 0.65rem !important;
}

#marker {
  position: absolute;
  top: -14px;
  left: 0;
  width: 1px;
  height: 10px;
  margin-left: -3.5%;
}

#marker:before {
  content: "\25B4";
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
}
</style>
