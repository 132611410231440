import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";
import Home from "../views/home/Home";
import HomeDsp from "../views/home/HomeDsp";

// import { cp } from "fs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: 'home default',
    component: Home,
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeDsp,
        meta: {
          middleware: "auth",
        },
      },
      {
        path: "/home/transsion",
        name: "Transsion home",
        component: () => import("../views/home/HomeTranssion.vue"),
        meta: {
          middleware: "auth",
        },
      },
      {
        path: "/home/opera",
        name: "Opera home",
        component: () => import("../views/home/HomeOpera.vue"),
        meta: {
          middleware: "auth",
        },
      },
      {
        path: "/home/truecaller",
        name: "Truecaller",
        component: () => import("../views/home/HomeTruecaller.vue"),
        meta: {
          middleware: "auth",
        },
      },
      {
        path: "/home/phoenix",
        name: "Phoenix",
        component: () => import("../views/home/HomePhoenix.vue"),
        meta: {
          middleware: "auth",
        },
      },
      {
        path: "/home/facebook",
        name: "Facebook Dashboard",
        meta: {
          middleware: "auth",
        },
        component: () => import("../views/home/HomeFaceBook"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      middleware: "guest",
    },
  },
  {
    path: "/accept-invitation",
    name: "accept-invitation",
    component: () => import("../views/auth/AcceptInvitation.vue"),
    meta: {
      middleware: "guest",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/auth/ResetPassword.vue"),
    meta: {
      middleware: "guest",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Signup,
    meta: {
      middleware: "guest",
    },
  },
  {
    path: "/tradedesk/campaigns",
    name: "campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/add-audience",
    name: "add-programmatic-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/creatives",
    name: "programmatic-creatives",
    component: () => import("../views/mediapal/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/add-banner",
    name: "add-programmatic-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/edit-banner/:id",
    name: "edit-programmatic-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/campaigns/:id",
    name: "campaign",
    component: () => import("../views/mediapal/singleReportingView.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/campaigns/:id/edit",
    name: "edit-dsp-campaign",
    component: () => import("../views/mediapal/editCampaign"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/campaigns/:id/view",
    name: "view-dsp-campaign",
    component: () => import("../views/mediapal/editCampaign"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/reporting/:id",
    name: "report",
    component: () => import("../views/mediapal/singleReportingView.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/reporting",
    name: "reporting",
    component: () => import("../views/mediapal/reporting.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/leadgen",
    name: "leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/tradedesk/targeting",
    name: "programmatic-targeting",
    component: () => import("../views/mediapal/targeting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/pretargets/:id",
    name: "pretarget",
    component: () => import("../views/mediapal/pretargeting/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/add-new-pretarget",
    name: "create-pretarget",
    component: () => import("../views/mediapal/pretargeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/update-pretarget/:id",
    name: "edit-pretarget",
    component: () => import("../views/mediapal/pretargeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/edit-publisher-inventory/:id",
    name: "edit-inventory",
    component: () => import("../views/mediapal/pretargeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/add-targeting-rules",
    name: "add-programmatic-targeting-rules",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/update-targeting-rule/:id",
    name: "edit-targeting-rules",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/delete-targeting-rule/:id",
    name: "delete-targeting-rules",
    component: () => import("../views/mediapal/targeting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/user-lists",
    name: "user-lists-and-remarketing",
    component: () => import("../views/mediapal/user-lists/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/user-list/:id",
    name: "user-list",
    component: () => import("../views/mediapal/user-lists/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/create-user-lists",
    name: "create-user-list",
    component: () => import("../views/mediapal/user-lists/create.vue"),
  },
  {
    path: "/tradedesk/edit-userlist/:id",
    name: "update-user-list",
    component: () => import("../views/mediapal/user-lists/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/creative-studio",
    name: "creative-studio",
    component: import("../views/mediapal/creative-studio.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/create-campaign",
    name: "new-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/campaign-planning",
    name: "campaign-planning",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/tradedesk/discovery",
    name: "discovery",
    component: () => import("../views/discovery/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/programmatic",
    name: "programmatic",
    component: () => import("../views/twitter/sentimental-analysis.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/discovery",
    name: "opera-discovery",
    component: () => import("../views/opera/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/creatives",
    name: "opera-creatives",
    component: () => import("../views/opera/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/reporting",
    name: "opera-reporting",
    component: () => import("../views/opera/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/leadgen",
    name: "opera-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/truecaller/reporting",
    name: "truecaller-reporting",
    component: () => import("../views/truecaller/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/discovery",
    name: "truecaller-discovery",
    component: () => import("../views/truecaller/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/creatives",
    name: "truecaller-creatives",
    component: () => import("../views/truecaller/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/leadgen",
    name: "truecaller-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/transsion/creatives",
    name: "transsion-creatives",
    component: () => import("../views/transsion/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/transsion/reporting",
    name: "transsion-reporting",
    component: () => import("../views/transsion/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/discovery",
    name: "transsion-discovery",
    component: () => import("../views/transsion/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/leadgen",
    name: "transsion-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/billing",
    name: "billing",
    component: () => import("../views/billing/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/invoicing",
    name: "invoices",
    component: () => import("../views/invoicing/invoices/index.vue"),
  },
  {
    path: "/payment-methods",
    name: "payment-methods",
    component: () => import("../views/invoicing/payment-methods/index.vue"),
  },
  {
    path: "/payment-history",
    name: "payment-history",
    component: () => import("../views/invoicing/payment-history/index.vue"),
  },
  {
    path: "/purchase-orders",
    name: "purchase-orders",
    component: () => import("../views/invoicing/purchase-orders/index.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("../views/invoicing/customers/index.vue"),
  },
  {
    path: "/invoicing/:id",
    name: "invoice-preview",
    component: () => import("../views/invoicing/invoices/invoice.vue"),
  },
  {
    path: "/facebook/ad-accounts",
    name: "facebook-ad-accounts",
    component: () => import("../views/facebook/adaccounts/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/campaigns",
    name: "facebook",
    component: () => import("../views/facebook/campaigns/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ad-sets",
    name: "facebook-ad-sets",
    component: () => import("../views/facebook/adsets/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ad-sets/:id",
    name: "facebook-ad-set",
    component: () => import("../views/facebook/adsets/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ads",
    name: "facebook-ads",
    component: () => import("../views/facebook/ads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ads/:id",
    name: "facebook-ad",
    component: () => import("../views/facebook/ads/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ads/:id/edit",
    name: "edit-facebook-ad",
    component: () => import("../views/facebook/ads/update.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/:account_id/campaigns/:id",
    name: "facebook-campaign",
    component: () => import("../views/facebook/campaigns/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/create-campaign",
    name: "create-facebook-campaign",
    component: () => import("../views/facebook/campaigns/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/edit-adset/:id",
    name: "update-facebook-adset",
    component: () => import("../views/facebook/campaigns/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/delete-adset/:id",
    name: "delete-adset",
    component: () => import("../views/facebook/adsets/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/edit-campaign/:id",
    name: "update-facebook-campaign",
    component: () => import("../views/facebook/campaigns/create.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/delete-campaign/:id",
    name: "delete-facebook-campaign",
    component: () => import("../views/facebook/campaigns/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/facebook/ad-accounts/:id",
    name: "get-ad-account",
    component: () => import("../views/facebook/adaccounts/view.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audiences/insights",
    name: "insights",
    component: () => import("../views/audiences/insights.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audiences/rules",
    name: "rules",
    component: () => import("../views/mediapal/targetingrules/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audience/rules/add_rule",
    name: "add-rtb-rule",
    component: () => import("../views/mediapal/targetingrules/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audience/rules/edit_rule/:id",
    name: "edit-rtb-rule",
    component: () => import("../views/mediapal/targetingrules/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audience/rules/delete_rule/:id",
    name: "delete-rtb-rule",
    component: () => import("../views/mediapal/targetingrules/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/google-search",
    name: "google-search",
    component: () => import("../views/google/google_search.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/google-ads",
    name: "google-ads",
    component: () => import("../views/google/google_ads.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/directdeals",
    name: "directdeals",
    component: () => import("../views/twitter/sentimental-analysis.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/direct-deals/deals",
    name: "direct-deals",
    component: () => import("../views/direct_deals/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/audio",
    name: "audio",
    component: () => import("../views/audio/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/dooh",
    name: "dooh",
    component: () => import("../views/dooh/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/campaigns",
    name: "twitter-campaigns",
    component: () => import("../views/twitter/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/creatives",
    name: "twitter-creatives",
    component: () => import("../views/twitter/creatives.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/audiences",
    name: "twitter-audiences",
    component: () => import("../views/twitter/audiences.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/campaign-reports",
    name: "twitter-campaign-reports",
    component: () => import("../views/twitter/campaign-reports.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/campaign-optimization",
    name: "twitter-campaign-optimization",
    component: () => import("../views/twitter/campaign-optimization.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/twitter/sentimental-analysis",
    name: "twitter-sentimental-analysis",
    component: () => import("../views/twitter/sentimental-analysis.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/youtube",
    name: "youtube",
    component: () => import("../views/twitter/sentimental-analysis.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/youtube/campaigns",
    name: "youtube-campaigns",
    component: () => import("../views/youtube/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/youtube/ad-groups",
    name: "youtube-ad-groups",
    component: () => import("../views/youtube/ad-groups.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/youtube/ads-link",
    name: "youtube-ads-link",
    component: () => import("../views/youtube/ads-link.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/settings/index.vue"),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/users-management/users",
    name: "all-users",
    component: () => import("../views/users/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/add-user",
    name: "add-user",
    component: () => import("../views/users/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/edit-user/:id",
    name: "update-user",
    component: () => import("../views/users/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/roles",
    name: "all-roles",
    component: () => import("../views/users/roles/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/add-role",
    name: "add-role",
    component: () => import("../views/users/roles/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/edit-role/:id",
    name: "update-role",
    component: () => import("../views/users/roles/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/clients",
    name: "all-clients",
    component: () => import("../views/users/clients/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/add-client",
    name: "add-client",
    component: () => import("../views/users/clients/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/edit-client/:id",
    name: "update-client",
    component: () => import("../views/users/clients/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/delete-client/:id",
    name: "delete-client",
    component: () => import("../views/users/clients/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/agencies",
    name: "all-agencies",
    component: () => import("../views/users/agencies/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/add-agency",
    name: "add-agency",
    component: () => import("../views/users/agencies/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/edit-agency/:id",
    name: "update-agency",
    component: () => import("../views/users/agencies/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/delete-agency/:id",
    name: "delete-agency",
    component: () => import("../views/users/agencies/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/permissions",
    name: "all-permissions",
    component: () => import("../views/users/permissions/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/add-permission",
    name: "add-permission",
    component: () => import("../views/users/permissions/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/users-management/edit-permission/:id",
    name: "update-permission",
    component: () => import("../views/users/permissions/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "*",
    name: "page-not-found",
    component: () => import("../views/PageNotFound.vue"),
  },

  {
    path: "/truecaller/campaigns",
    name: "truecaller-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/create-campaign",
    name: "new-truecaller-campaign",
    component: () => import("../views/mediapal/create.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/campaigns",
    name: "opera-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/create-campaign",
    name: "new-opera-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/campaigns",
    name: "phoenix-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/create-campaign",
    name: "new-phoenix-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/creatives",
    name: "phoenix-creatives",
    component: () => import("../views/phoenix/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/discovery",
    name: "phoenix-discovery",
    component: () => import("../views/phoenix/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/reporting",
    name: "phoenix-reporting",
    component: () => import("../views/phoenix/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/leadgen",
    name: "phoenix-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  //google temporary fix
  {
    path: "/google/campaigns",
    name: "google-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/create-campaign",
    name: "new-google-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/creatives",
    name: "google-creatives",
    component: () => import("../views/phoenix/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/discovery",
    name: "google-discovery",
    component: () => import("../views/phoenix/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/reporting",
    name: "google-reporting",
    component: () => import("../views/phoenix/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/leadgen",
    name: "google-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/audiences",
    name: "google-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/google/add-audience",
    name: "add-google-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  // end google fix
  {
    path: "/opera/audiences",
    name: "opera-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/add-audience",
    name: "add-opera-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/audiences",
    name: "phoenix-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/add-audience",
    name: "add-phoenix-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/audiences",
    name: "truecaller-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/add-audience",
    name: "add-truecaller-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/add-audience",
    name: "add-transsion-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/audiences",
    name: "transsion-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/campaigns",
    name: "transsion-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/create-campaign",
    name: "new-transsion-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/opera/add-banner",
    name: "add-opera-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/transsion/add-banner",
    name: "add-transsion-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/truecaller/add-banner",
    name: "add-truecaller-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/phoenix/add-banner",
    name: "add-phoenix-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  //ctv campaigns
  {
    path: "/ctv/discovery",
    name: "ctv-discovery",
    component: () => import("../views/ctv/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/campaigns",
    name: "ctv-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/creatives",
    name: "ctv-creatives",
    component: () => import("../views/ctv/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/create-campaign",
    name: "new-ctv-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/add-banner",
    name: "add-ctv-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/reporting",
    name: "ctv-reporting",
    component: () => import("../views/ctv/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/audiences",
    name: "ctv-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/add-audience",
    name: "add-ctv-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/ctv/leadgen",
    name: "ctv-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  //end ctv campaigns

  //gaming
  {
    path: "/gaming/campaigns",
    name: "gaming-campaigns",
    component: () => import("../views/mediapal/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/creatives",
    name: "gaming-creatives",
    component: () => import("../views/gaming/creatives/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/create-campaign",
    name: "new-gaming-campaign",
    component: () => import("../views/mediapal/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/add-banner",
    name: "add-gaming-banner",
    component: () => import("../views/mediapal/creatives/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/reporting",
    name: "gaming-reporting",
    component: () => import("../views/gaming/reporting/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/audiences",
    name: "gaming-audiences",
    component: () => import("../views/mediapal/targeting/index.vue"),

    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/add-audience",
    name: "add-gaming-audience",
    component: () => import("../views/mediapal/targeting/create.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/discovery",
    name: "gaming-discovery",
    component: () => import("../views/gaming/discovery/discovery.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/gaming/leadgen",
    name: "gaming-leadgen",
    component: () => import("../views/mediapal/leads/index.vue"),
    meta: {
      middleware: "auth",
    },
  },
  //end gaming
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'login' && !localStorage.getItem("access_token")) next({ name: 'login' })
//   else next()
// })

router.beforeEach((to, from, next) => {
  if (to.meta.middleware == "guest") {
    if (store.state.authenticated && localStorage.getItem("access_token")) {
      next({ name: "home" });
    }
    next();
  } else {
    if (store.state.authenticated && localStorage.getItem("access_token")) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});
function title(params) {
  let arr = params.split("-");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = title(to.name) || "Tradedesk";
  });
});

export default router;
