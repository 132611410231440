<template>
    <v-row>
        <v-col cols="7">
            <v-card height="420" :loading="isLoading['fetching_bids_data']" id="container" flat>
                <v-card-title class="d-flex align-center py-1">
                    <h5 class="mr-auto">Bidder Health </h5>
                </v-card-title>
                <v-divider></v-divider>
                <apexchart class="mt-2" type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
            </v-card>
        </v-col>

        <v-col v-if="false" cols="7">
            <v-card :loading="isLoading['fetching_publishers']" height="420" flat>
                <v-card-title class="py-1">
                    <h5>Top Publishers </h5>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <span v-for="item in top_publishers" :key="item.publisher">

                        <v-row align="center">

                            <v-col cols="6">
                                <h5 class="font-weight-medium text--secondary text-capitalize">
                                    {{ item.publisher }}
                                </h5>
                            </v-col>

                            <v-col cols="6" align="right">
                                <h6>{{ item.percentage }}</h6>
                                <v-progress-linear rounded :value="item.percentage" height="7"
                                    color="#6092c0"></v-progress-linear>
                            </v-col>
                        </v-row>

                    </span>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="5">
            <ActiveBidderCampaigns />
        </v-col>
    </v-row>
</template>

<script>
import ActiveBidderCampaigns from './ActiveBidderCampaigns.vue';

export default {
    components: {
        ActiveBidderCampaigns
    },
    data() {
        return {
            size: "30m",
            interval: 10,
            isLoading: {
                fetching_bids_data: false,
                fetching_publishers: false,
            },
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    toolbar: { show: false },
                },
                noData: {
                    text: "No Data Available 😐",
                    align: "center",
                    verticalAlign: "middle",
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [],
                },
                fill: {
                    opacity: 1
                },
                colors: ['#77c1ab', '#6092c0', '#d36085', '#ffb01a', '#00E396', '#80c7fd', '#008FFB', '#80f1cb', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val;
                        }
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                }
            },
        }
    },
    //props
    props: {
        color: {
            type: String,
            default: "#80CBC4"
        },
        data: {
            type: Array,
            default: () => []
        }
    },

    watch: {
        histogram_data: {
            handler: function (val) {

                //remove entries where buckets are empty
                val = val.filter(bucket => bucket.addId_histogram.buckets.length > 0);

                let categories = val.map(bucket => {
                    const date = new Date(bucket.key_as_string);
                    const formattedDate = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    }).format(date);

                    return formattedDate;
                });

                //return only the latest 10 entries for categories
                categories = categories.slice(-10);

                //prepare series data
                const uniqueKeys = Array.from(new Set(val.flatMap(bucket => bucket.addId_histogram.buckets.map(item => item.key))));

                const seriesData = uniqueKeys.map(key => {
                    return {
                        name: key,
                        data: val.map(bucket => {
                            const matchingBucket = bucket.addId_histogram.buckets.find(item => item.key === key);
                            return matchingBucket ? matchingBucket.doc_count : 0;
                        })
                    };
                });

                //return only the latest 10 entries
                seriesData.forEach(item => {
                    item.data = item.data.slice(-10);
                });

                //return only the latest 10 entries for categories
                categories = categories.slice(-10);


                //set that to series
                this.series = seriesData;

                // //set that to chart options
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        ...this.chartOptions.xaxis,
                        categories
                    }
                };
            },
            deep: true
        },
    },

    mounted() {
        this.fetchCampaignsHistogramData();
        this.getTopPublishers();
    },

    computed: {
        histogram_data() {
            return this.$store.state.dsp.histogram_data;
        },
        top_publishers() {

            let data = this.$store.state.dsp.top_publishers;

            const relevantData = data[data.length - 1].addId_histogram.buckets.map(bucket => {
                const publisher = this.formatPublisher(bucket.key);
                const docCount = bucket.doc_count;
                return { publisher, docCount };
            });

            // Calculate total doc count
            const totalDocCount = relevantData.reduce((acc, curr) => acc + curr.docCount, 0);

            // Calculate percentage for each publisher
            const formattedData = relevantData.map(entry => {
                const percentage = this.calculatePercentage(entry.docCount, totalDocCount);
                return { ...entry, percentage };
            });

            formattedData.splice(8);

            return formattedData;
        }
    },

    methods: {
        formatPublisher(publisher) {
            return decodeURIComponent(publisher.replace(/%20/g, ' '));
        },
        calculatePercentage(count, total) {
            let percentage =  (count / total) * 100;
            return percentage.toFixed(2) + "%";
        },
        async fetchCampaignsHistogramData() {

            let filters = {
                interval: this.interval,
                size: this.size,
            };

            this.isLoading['fetching_bids_data'] = true;

            try {

                await this.$store.dispatch("dsp/fetCampaignHistogramData", filters);

            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading['fetching_bids_data'] = false;
            }
        },

        async getTopPublishers() {

            let filters = {
                interval: this.interval,
                size: this.size,
            };

            this.isLoading['fetching_publishers'] = true;

            try {

                await this.$store.dispatch("dsp/getTopPublishers", filters);

            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading['fetching_publishers'] = false;
            }
        },
    }
}
</script>


<style scoped>
#container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
}

.card-title {
    flex-wrap: nowrap !important;
    display: block !important;
}
</style>
