<template>
  <div class="mt-2">
    <TodayCardsProgrammatic v-if="superAdmin" :loading="cardsLoading" />

    <!-- <TotalCardsSectionProgrammatic /> -->
    <CampaignOverviewChart @filterReportByStatus="filterReportByStatus($event)" />

    <!--Data Insights-->
    <RealTimeELKData v-if="superAdmin" class="mb-2" />

    <ProgrammaticAppsTable @filterGraphByCampaign="filterGraphByCampaign($event)"
      @resetCampaignsFilter="resetCampaignsFilter($event)" />

    <BannerSubmissions :dsp_creatives="dsp_creatives" :disapproved_creatives="disapproved_creatives"
      :unsubmitted_creatives="unsubmitted_creatives" @submitUnsubmittedBanner="handleSubmit($event)"
      @submitDisapprovedBanner="DisapprovedSubmit($event)" @submitDisapprovedMultiple="DisapprovedSubmitMultiple($event)"
      @submitUnsubmittedMultiple="UnsubmittedSubmitMultiple($event)"
      @triggerUnsubPageChange="readPaginatedUnsubmittedCreatives($event)"
      @triggerDisapprovedPageChange="readPaginatedDisapprovedCreatives($event)" />
  </div>
</template>

<script>
import TodayCardsProgrammatic from "../../components/Home/TodayCardsProgrammatic.vue";
import CampaignOverviewChart from "../../components/Home/CampaignOverviewChart.vue";
import BannerSubmissions from "../../components/banners.vue";
import ProgrammaticAppsTable from "../../components/Tables/ProgrammaticAppsTable.vue";
import RealTimeELKData from "../../components/Home/RealTimeELKData.vue";

export default {
  name: "HomeDsp",
  components: {
    BannerSubmissions,
    CampaignOverviewChart,
    TodayCardsProgrammatic,
    ProgrammaticAppsTable,
    RealTimeELKData
  },
  data: () => ({
    loading: true,
    unsubmitted_id: "",
    totals_data: [],
    disapproved_banners: [],
    unsubmitted_banners: [],
    active_campaigns: [],
    paused_campaigns: [],
    in_review: [],
    all_campaigns: [],
    completed_campaigns: [],
    campaign_count: [],
    impressions_store: [],

    client: "",
    status: "Active",
    filterByCampaigns: [],
    cardsLoading: false,
  }),

  async mounted() {
    // console.log(this.superAdmin)
    //get home/total campaign stats
    this.getHomeCampaignStats();
    // get all reports
    this.getAllCampaignsReports();

    //Unsubmitted Banners
    this.readPaginatedUnsubmittedCreatives();

    //Disaproved banners
    this.readPaginatedDisapprovedCreatives();

    //Todays Insights
    this.programmaticTodayInsights();

    //Advertisers
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAllAdvertisers");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
    }

    //Advertisers
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAgencies");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
    }
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    general_facebook_reports() {
      return this.$store.state.facebook.general_facebook_reports;
    },
    home_campaign_stats() {
      return this.$store.state.dsp.home_campaign_statistics;
    },
    dsp_creatives() {
      return this.$store.state.dsp.dsp_creatives;
    },
    disapproved_creatives() {
      return this.$store.state.dsp.disapproved_creatives;
    },
    unsubmitted_creatives() {
      return this.$store.state.dsp.unsubmitted_creatives;
    },
  },
  methods: {
    //filter graph data by campaign
    filterGraphByCampaign(campaigns) {
      this.filterByCampaigns = campaigns

      this.getAllCampaignsReports();
    },
    resetCampaignsFilter() {
      this.filterByCampaigns = []
      this.getAllCampaignsReports();
    },
    filterReportByStatus(status) {
      this.status = status;

      this.getAllCampaignsReports();
    },
    async getHomeCampaignStats() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getHomeCampaignStats");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async getAllCampaignsReports() {
      //Campaigns
      let req_body = {
        group_by: "daily",
        status_filter: this.status,
        campaigns: this.filterByCampaigns
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAllCampaignsReports", req_body);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async handleSubmit(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitBanner", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Creative submitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the Banner. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async DisapprovedSubmit(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/resubmitBanner", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Creative resubmitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem resubmitting the Banner. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async DisapprovedSubmitMultiple() {
      const disapproved_banner_ids = this.disapproved_banners.map(
        (banner) => banner.banner_id
      );

      let body = {
        banner_ids: disapproved_banner_ids,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitManyBanners", body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Disapproved Creatives resubmitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem resubmitting the banners. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async UnsubmittedSubmitMultiple(ids) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitUnsubBanner", ids);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Unsubmitted Creatives submitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the banners. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readPaginatedUnsubmittedCreatives(value) {
      const page = value != undefined ? value : 1;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getUnsubCreative", page);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readPaginatedDisapprovedCreatives(value) {
      const page = value != undefined ? value : 1;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getDisapCreative", page);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    //Today's Insights
    async programmaticTodayInsights() {
      this.cardsLoading = true;

      try {
        await this.$store.dispatch("dsp/getProgrammaticTodayInsights");
      } catch (error) {
        this.error = error;
      } finally {
        this.cardsLoading = false;
      }
    },
  },
};
</script>
