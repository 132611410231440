<template>
  <div>
    <v-row v-if="loading">
      <v-col v-for="(item, index) in Array.from({ length: 4 })" :key="index" lg="3" md="6" cols="12">
        <v-card flat>
          <v-skeleton-loader type="list-item-three-line, card-heading"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else class="mt-5">

      <v-col lg="3" md="6" cols="12">
        <v-card flat>
          <v-card-title class="grey--text pb-1 overline">Impressions today</v-card-title>
          <v-card-text>
            <div class="display-1 pb-2">
              {{ todaysImpressions.toLocaleString("en") }}
            </div>

            <div class="d-flex align-center justify-space-between">
              <span class="grey--text">Last 24 hours</span>

              <v-chip small :color="cardsColorAndSign(calculatePercentageChange(todaysClicks, yesterdayClicks))?.color"
                label
                :text-color="cardsColorAndSign(calculatePercentageChange(todaysImpressions, yesterdayImpressions))?.textColor"
                class="font-weight-bold">
                <v-icon small left>
                  {{ cardsColorAndSign(calculatePercentageChange(todaysImpressions, yesterdayImpressions))?.icon }}
                </v-icon>
                {{
                  calculatePercentageChange(todaysImpressions, yesterdayImpressions) }} %
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat>
          <v-card-title class="grey--text pb-1 overline">Spend today</v-card-title>
          <v-card-text>
            <div class="display-1 pb-2">
              ${{ todaysSpend.toLocaleString("en") }}
            </div>

            <div class="d-flex align-center justify-space-between">
              <span class="grey--text">Last 24 hours</span>

              <v-chip small class="font-weight-bold" :color="cardsColorAndSign(calculatePercentageChangeForCPM())?.color"
                label :text-color="cardsColorAndSign(calculatePercentageChangeForCPM())?.textColor">

                <v-icon small left>
                  {{ cardsColorAndSign(calculatePercentageChangeForCPM())?.icon }}
                </v-icon>
                {{
                  calculatePercentageChangeForCPM() }} %
              </v-chip>
            </div>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat>
          <v-card-title class="grey--text pb-1 overline">Clicks today</v-card-title>
          <v-card-text>
            <div class="display-1 pb-2">
              {{ todaysClicks.toLocaleString("en") }}
            </div>

            <div class="d-flex align-center justify-space-between">
              <span class="grey--text">Last 24 hours</span>

              <v-chip class="font-weight-bold" small
                :color="cardsColorAndSign(calculatePercentageChange(todaysClicks, yesterdayClicks))?.color" label
                :text-color="cardsColorAndSign(calculatePercentageChange(todaysImpressions, yesterdayImpressions))?.textColor">
                <v-icon small left>
                  {{ cardsColorAndSign(calculatePercentageChange(todaysClicks, yesterdayClicks))?.icon }}
                </v-icon>
                {{
                  calculatePercentageChange(todaysClicks, yesterdayClicks) }} %
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat>
          <v-card-title class="grey--text pb-1 overline">AVG.CTR TODAY</v-card-title>
          <v-card-text>
            <div class="display-1 pb-2">
              {{ todaysCTR }}%
            </div>

            <div class="d-flex align-center justify-space-between">
              <span class="grey--text">Last 24 hours</span>

              <v-chip class="font-weight-bold" small
                :color="cardsColorAndSign(calculatePercentageChange(todaysCTR, yesterdayCTR))?.color" label
                :text-color="cardsColorAndSign(calculatePercentageChange(todaysCTR, yesterdayCTR))?.textColor">
                <v-icon small left>
                  {{ cardsColorAndSign(calculatePercentageChange(todaysCTR, yesterdayCTR))?.icon }}
                </v-icon>
                {{
                  calculatePercentageChange(todaysCTR, yesterdayCTR) }} %
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      todaysImpressions: 0,
      todaysSpend: 0,
      todaysCTR: 0,
      todaysClicks: 0,
      yesterdayImpressions: 0,
      yesterdaySpend: 0,
      yesterdayCTR: 0,
      yesterdayClicks: 0,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    insights() {
      return this.$store.state.dsp.today_insights;
    },
  },
  watch: {
    insights() {
      this.augmentValues();
    },
  },
  methods: {
    augmentValues() {

      let todayInsights = this.insights?.today;
      let yesterdayInsights = this.insights?.yesterday;

      this.todaysImpressions = todayInsights?.total_impressions
        ? parseInt(todayInsights?.total_impressions)
        : 0;

      this.todaysSpend = todayInsights?.total_spend
        ? parseInt(todayInsights?.total_spend)
        : 0;

      this.todaysClicks = todayInsights?.total_clicks
        ? parseInt(todayInsights?.total_clicks)
        : 0;

      this.todaysCTR = todayInsights?.average_ctr
        ? parseFloat(todayInsights?.average_ctr).toFixed(3)
        : 0;

      this.yesterdayImpressions = yesterdayInsights?.total_impressions
        ? parseInt(yesterdayInsights?.total_impressions)
        : 0;

      this.yesterdaySpend = yesterdayInsights?.total_spend
        ? parseInt(yesterdayInsights?.total_spend)
        : 0;

      this.yesterdayClicks = yesterdayInsights?.total_clicks
        ? parseInt(yesterdayInsights?.total_clicks)
        : 0;

      this.yesterdayCTR = yesterdayInsights?.average_ctr
        ? parseFloat(yesterdayInsights?.average_ctr).toFixed(3)
        : 0;

    },

    calculatePercentageChange(todayValue, yesterdayValue) {

      todayValue = parseFloat(todayValue);
      yesterdayValue = parseFloat(yesterdayValue);

      if (!isNaN(todayValue) && !isNaN(yesterdayValue)) {
        // Calculate percentage change
        let change = ((todayValue - yesterdayValue) / yesterdayValue) * 100;

        //check if change is a NAN
        if (isNaN(change)) {
          return 0;
        }

        // Round the value to two decimal places
        change = Math.round(change * 100) / 100;

        return change;
      } else {
        return 0;
      }
    },

    calculatePercentageChangeForCPM() {
      let todaysCPM = this.todaysSpend / this.todaysImpressions * 1000;
      let yesterdaysCPM = this.yesterdaySpend / this.yesterdayImpressions * 1000;

      return this.calculatePercentageChange(todaysCPM, yesterdaysCPM);

    },
    cardsColorAndSign(value) {
      if (value > 0) {
        return {
          color: 'green lighten-5',
          textColor: 'success',
          sign: '+',
          icon: 'mdi-trending-up'
        };
      } else if (value < 0) {
        return {
          color: '#fee2e1',
          sign: '-',
          textColor: 'error',
          icon: 'mdi-trending-down'
        };
      } else {
        return {
          color: 'blue-grey-lighten-4',
          textColor: 'grey',
          sign: '',
          icon: 'mdi-trending-neutral'
        };
      }
    }
  },
};
</script>
