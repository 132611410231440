export default {
    facebook_campaigns: [],
    mediapal_social_campaigns: [],
    paginated_facebook_campaigns: null,
    facebook_campaign: null,
    facebook_campaign_insights: null,
    facebook_time_based_campaign_insights: [],
    facebook_combined_campaign_insights:null,
    facebook_adsets_by_account: [],
    facebook_adsets_by_campaign: [],
    facebook_single_adset: null,
    facebook_ads: [],
    ad_account: null,
    single_ad_account: null,
    update_facebook_adset: null,
    deleted_facebook_adset: null,
    adaccount_report_run_id: null,
    adaccount_report_status: null,
    facebook_adaccount_insights: [],
    ad_accounts: [],
    facebook_adset_insights: [],
    facebook_adset_ads: [],
    facebook_ad: null,
    facebook_time_based_adset_insights: [],
    facebook_age_adset_insights: [],
    facebook_ad_creatives: [],
    facebook_ad_insights: null,
    facebook_creative: null,
    facebook_client_pages: [],
    facebook_ad_creative: null,
    custom_audiences: [],
    all_countries: [],
    all_regions: [],
    all_cities: [],
    zip_codes: [],
    all_languages: [],
    electoral_districts: [],
    specific_location: [],
    available_interests: [],
    available_behaviors: [],
    employers: [],
    available_demographics: [],
    life_events: [],
    facebook_age_based_campaign_insights: [],
    ad_images: [],
    ad_image: "src/assets/mediapal.png",
    facebook_post_preview: "",
    industries: [],
    income: [],
    all_majors: [],
    job_titles: [],
    user_os: [],
    ad_account_id: "",
    updated_facebook_campaign: [],
    general_facebook_reports: null,
    general_facebook_age_report: null,
    facebook_cards_insights: null,
    ad_video: null,
    special_error: "",
    facebook_today_insights: null,
    updated_facebook_campaign_fees:[]
}