export default {
    loggedIn(state) {
        if (state.auth.access_token) {
            return true;
        }
        return false;
    },
    superAdmin(state) {
        if (JSON.parse(state.auth.roles).find(e => e.name === 'SuperAdmin')) {
            return true;
        }
        return false;
    },


    authenticated(state){
        return state.authenticated;
    },
    authenticatedUser(state){
        return state.authenticated_user;
    }
}