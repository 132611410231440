export default {
    payment_information: null,
    customers: [],
    customer: null,
    campaign_billing_metadata: null,
    campaign_invoice: null,
    invoices: [],
    invoice: null,
    tax_types: [],
    purchase_orders: [],
    purchase_order: null,
    invoices_overview: null,
}