<template>
  <v-app class="ma-n3">
    <v-container fluid>

      <v-row v-if="_isSafaricom" no-gutters>

        <v-col class="main-part d-lg-flex">

          <div class="align-center mb-5">
            <v-img src="saf-logo.png" width="180"></v-img>
          </div>

          <div class="text-center">

            <h1 class="text-h5 font-weight-bold mb-4">
              Welcome to Safaricom Ads
            </h1>

            <p class="text-body-2">
              Please sign-in to access your account
            </p>

          </div>

          <v-card class="mt-3" width="500">

            <v-card-text class="py-5">
              <v-container>
                <v-row no no-gutters class="flex-column">
                  <v-form>

                    <v-col>
                      <v-text-field v-model="email_address" label="Email Address" type="email" :rules="emailRules"
                        required outlined dense placeholder="Enter email address..." persistent-placeholder
                        hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="password" dense type="password" label="Password" :rules="passwordRules"
                        outlined required placeholder="Enter password..." persistent-placeholder
                        hide-details="auto"></v-text-field>
                    </v-col>


                    <v-col>
                      <a @click="redirectToForgotPassword">Forgot Password?</a>
                    </v-col>

                    <v-col>
                      <v-btn large block depressed color="primary" @click.prevent="onSubmit">
                        Sign In</v-btn>
                    </v-col>

                    <v-col>
                      <v-divider></v-divider>
                    </v-col>

                  </v-form>

                </v-row>
              </v-container>
            </v-card-text>

          </v-card>
        </v-col>


      </v-row>

      <v-row v-if="!_isSafaricom" no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img v-bind:src="this.logo"></v-img>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center bg-primary flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <div class="d-flex d-lg-none mb-10">
                  <v-img v-bind:src="this.logo"></v-img>
                </div>
                <v-form>
                  <v-container>
                    <v-row no no-gutters class="flex-column">
                      <v-col>
                        <h1 class="text-h5 font-weight-bold mb-4">
                          Welcome to Tradedesk
                        </h1>
                        <p class="text-body-2 mb-8">
                          Please sign-in to your account
                        </p>
                      </v-col>

                      <v-form>
                        <v-col>
                          <v-text-field v-model="email_address" label="Email Address" type="email" :rules="emailRules"
                            required outlined dense placeholder="Enter email address..." persistent-placeholder
                            hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field v-model="password" dense type="password" label="Password" :rules="passwordRules"
                            outlined required placeholder="Enter password..." persistent-placeholder
                            hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col>
                          <a @click="redirectToForgotPassword">Forgot Password?</a>
                        </v-col>

                        <v-col>
                          <v-btn large block depressed color="primary" @click.prevent="onSubmit" :disabled="password.length === 0 ||
                            email_address.length === 0
                            ">
                            Sign In</v-btn>
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text text-caption">
                  <a href="https://mediapal.net/" class="text-decoration-none">Tradedesk</a>, All rights reserved.
                </div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
// import ErrorMessage from "../../components/ErrorMessage.vue";
export default {
  data: () => ({
    email_address: "",
    password: "",
    emailRules: [(v) => !!v || "E-mail is required"],
    passwordRules: [(v) => !!v || "Password is required"],
    credentials: {
      email_address: "",
      password: "",
    },
    params: {
      from: "",
      message: "",
    },
    error: null,
  }),
  components: {},
  computed: {
    // a computed getter
    logo() {
      if (location.hostname == "dsp.tag.co.ke") {
        return "https://live.mediapal.net/ads/logos/tradedesk/TAG.png";
      } else if (location.hostname == "desk.belvadigital.com") {
        return "https://live.mediapal.net/ads/logos/tradedesk/belva-dark.png";

      } else if (location.hostname == "safaricom.mediapal.net") {
        // return "saf-logo.png"
        return "https://www.mediapal.net/brands/safaricom.png";
      }

      return "https://live.mediapal.net/ads/logos/tradedesk/mediapal.png";
    },
    _isSafaricom() {
      return location.hostname == "safaricom.mediapal.net" ? true : false;
    }
  },
  methods: {
    onSubmit() {
      if (this.email_address === "" || this.password === "") {
        this.error = "Please fill in all values";
      } else {
        let body = {
          email: this.email_address,
          password: this.password,
        };
        this.loginRequest(body);
      }
    },
    async loginRequest(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("login", body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Login Successful!",
          group: "success",
        });
        if (this.$route.params.from !== undefined) {
          this.$router.push({ name: this.params.from });
        } else {
          this.$router.go()
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        // this.error = error.error;
        this.$notify({
          title: error.error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    redirectToForgotPassword() {
      this.$router.push({ name: "reset-password" });
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 !important;

  .logo-sm {
    height: 23%;
  }

  .main-part {
    width: 100%;
    height: 100vh;
    background-color: #f6f7ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-part {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;

    .login-wrapper {
      width: 320px;
      height: auto;
    }

    .v-footer {
      position: absolute;
      bottom: 12px;
      background-color: #fff;
    }
  }
}
</style>

