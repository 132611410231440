import init_state from "./state.js";

export default {
    resetBillingState(state) {
        Object.assign(state, init_state)
    },

    //Customers
    setCustomers(state, payload) {
        state.customers = payload.customers;
    },

    setCustomer(state, payload) {
        state.customer = payload.customer;
    },

    //Invoices
    setInvoices(state, payload) {
        state.invoices = payload.invoices;
    },

    setInvoice(state, payload) {
        state.invoice = payload.invoice;
    },

    //PurchaseOrder
    setPurchaseOrders(state, payload) {
        state.purchase_orders = payload.purchase_orders;
    },

    setPurchaseOrder(state, payload) {
        state.purchase_order = payload.purchase_order;
    },

    setInvoicesOverview(state, payload) {
        state.invoices_overview = payload.invoices_overview;
    }
}