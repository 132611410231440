<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="logs" no-data-text="No Changes">
      <template #[`item.date`]="{ item }">
        {{
          new Date(item.date).toLocaleString("en-US", {
            weekday: "short",
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })
        }}
      </template>
      <!-- <template #[`item.field_name`]="{ item }">
        {{ item.properties.oldValues.type }} to
        {{ item.properties.newValues.type }}
      </template>
      <template #[`item.old_date`]="{ item }">
        {{ new Date(item.properties.oldValues.activate_time).toUTCString() }}
      </template>
      <template #[`item.new_date`]="{ item }">
        {{ new Date(item.properties.newValues.activate_time).toUTCString() }}
      </template> -->
      <template #[`item.field`]="{ item }">
        {{ capitalizeAndAddSpaces(item.field) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    logs() {
      const logs = this.items;

      let newObjects = [];
      for (const obj of logs) {
        for (const key in obj.properties.attributes) {
          const attribute = obj.properties.attributes[key];
          const old = obj.properties.old[key];
          const fields = key;
          newObjects.push({
            date: obj.updated_at,
            user: obj?.causer?.name,
            field: fields,
            new: attribute,
            old: old,
          });
        }
      }
      return newObjects;
    },
  },
  methods: {
    capitalizeAndAddSpaces(str) {
      let words = str.split("_"); // Split the string into an array of words using underscores as the delimiter
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1); // Capitalize the first letter of each word
      }
      return words.join(" "); // Join the words back into a string with spaces between them
    },
  },
};
</script>
