<template>
  <v-dialog v-model="dialog" width="500" light overlay-opacity="0.5" persistent>
    <v-card>
      <v-card-title class="text-h6 error py-3 white--text"> Confirm Delete </v-card-title>

      <v-card-text class="pt-5">
        Are you sure you want to delete this record {{ item?.name }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dismiss"> Cancel </v-btn>
        <v-btn color="error" depressed @click="confirm"
          ><v-icon left> mdi-delete </v-icon> Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
  },
  methods: {
    confirm() {
      this.$emit("delete", this.item?.id);
      // this.$emit("delete", this.item.banner_id);
    },
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>

<style scoped>
.v-dialog {
  box-shadow: none !important;
}
</style>
