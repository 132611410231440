export default {
  getCampaignsByChannel: (state) => (channel) => {
    return state.dsp_campaigns.filter(
      (campaign) => campaign.channel === channel
    );
  },

  //Filter campaigns data by date
  getCampaignsByDate: (state) => (date) => {

    let currentDate = new Date();

    if (date == 'all') {

      return state.all_campaigns_reports;

    } else if (date == '7') {

      // Iterate through the data and filter for the last 7 days
      let last7DaysData = state.all_campaigns_reports.filter(item => {
        let itemDate = new Date(item.date);
        let timeDifference = currentDate - itemDate;
        let differenceInDays = timeDifference / (1000 * 3600 * 24);

        return differenceInDays <= 7;
      });

      return last7DaysData;

    } else if (date == '30') {
      // Filter for the last 30 days
      let last30DaysData = state.all_campaigns_reports.filter(item => {
        let itemDate = new Date(item.date);
        let timeDifference = currentDate - itemDate;
        let differenceInDays = timeDifference / (1000 * 3600 * 24);

        return differenceInDays <= 30;
      });

      return last30DaysData;
    } else if(date == '90'){
      // Filter for the last 90 days
      let last90DaysData = state.all_campaigns_reports.filter(item => {
        let itemDate = new Date(item.date);
        let timeDifference = currentDate - itemDate;
        let differenceInDays = timeDifference / (1000 * 3600 * 24);

        return differenceInDays <= 90;
      });

      return last90DaysData;
    } else if(date == '180'){
      // Filter for the last 180 days
      let last180DaysData = state.all_campaigns_reports.filter(item => {
        let itemDate = new Date(item.date);
        let timeDifference = currentDate - itemDate;
        let differenceInDays = timeDifference / (1000 * 3600 * 24);

        return differenceInDays <= 180;
      });

      return last180DaysData;
    }
  }
};
