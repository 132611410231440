<template>
  <v-row class="my-3">
    <v-col cols="12">
      <change-log-banner-modal :dialog="changeDialog" :item="mine" v-if="superAdmin"
        @dismiss="dismissModal"></change-log-banner-modal>
      <v-card flat>
        <v-card-title class="d-flex py-2">
          <h5 class="mr-auto">Campaigns Overview</h5>

          <v-select class="shrink" outlined dense return-object item-text="text" item-value="value" label="Status"
            @change="updateDateChartStatus($event)" v-model="chartStatus" :items="chartStatusValues" hide-details
            :menu-props="{ bottom: true, offsetY: true }"></v-select>
          <v-btn class="ml-3" depressed disabled @click="showLogs(item)" v-if="superAdmin">Change Log</v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <TotalCardsSectionProgrammatic :data="_graphData" />
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="2"><v-select class="shrink" outlined dense label="Metric" @change="selectSeries($event)"
                v-model="select_series" :items="__selectSeries1Data" hide-details
                :menu-props="{ bottom: true, offsetY: true }"></v-select>
            </v-col>
            <v-col cols class="pl-0 pr-0 shrink">
              <div class="d-flex align-center text-center">
                <div class="mt-2">vs</div>
              </div>
            </v-col>

            <v-col cols="2"><v-select class="shrink" outlined dense label="Select a Metric" placeholder="Select a Metric"
                @change="selectSeries2($event)" v-model="select_series2" :items="__selectSeries2Data" hide-details
                :menu-props="{ bottom: true, offsetY: true }"></v-select>
            </v-col>


            <v-col cols="2">
              <v-select class="shrink" outlined dense return-object item-text="text" item-value="value" label="Period"
                v-model="defaultChartDateRange" :items="chartDateRange" hide-details
                :menu-props="{ bottom: true, offsetY: true }"></v-select>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12">
              <apexchart type="line" height="300" v-if="apexLoading" :options="lineChartOptions" :series="series"
                ref="chart"></apexchart>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</template>
<script>
import config from "@/apex-config";
import TotalCardsSectionProgrammatic from "./TotalCardsSectionProgrammatic.vue";
import ChangeLogBannerModal from "../Programmatic/ChangeLogBannerModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TotalCardsSectionProgrammatic,
    ChangeLogBannerModal
  },
  data() {
    return {
      changeDialog: false,
      select_series: "Impressions",
      chartStatus: "Active",
      chartStatusValues: ["Active", "Completed"],
      select_series2: "CTR (%)",
      select_charts: ["Impressions", "Spend", "Clicks", "CTR (%)"],
      select_charts2: ["Impressions", "Spend", "Clicks", "CTR (%)"],
      defaultChartDateRange: { text: "Last 30 Days", value: 30 },
      chartDateRange: [
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
        { text: "Last 3 Months", value: 90 },
        { text: "Last 6 Months", value: 180 },
      ],
      config,
      mine: {},
      apexLoading: false,
      lineChartOptions: {
        chart: {
          type: "line",
          height: 300,
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          animation: {
            enabled: false,
          },
        },

        noData: {
          text: "No Active Campaigns...",
          align: "center",
          verticalAlign: "middle",
        },
        colors: [config.light.primary, config.light.error],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2],
          dashArray: [0, 0],
          curve: ["straight", "straight"],
        },

        markers: {
          size: 3,
          style: "hollow",
          hover: {
            sizeOffset: 4,
          },
        },
        xaxis: {
          type: "datetime",
        },

        yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 0,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
          },
        ],

        legend: {
          opposite: false,
          show: true,
          showForNullSeries: false,
          position: "top",
          horizontalAlign: "left",
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      series: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
      this.generateChartData();
    });
  },
  watch: {
    // all_campaigns_reports() {
    //   this.generateChartData();
    // },
    _graphData() {
      this.generateChartData();
    },
  },
  computed: {
    ...mapGetters({
      superAdmin: "superAdmin",
      getCampaignsByDate: "dsp/getCampaignsByDate",
    }),
    _graphData() {
      return this.getCampaignsByDate(this.defaultChartDateRange?.value);
    },
    all_campaigns_reports() {
      return this.$store.state.dsp.all_campaigns_reports;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    __selectSeries1Data() {
      return this.select_charts2.filter((item) => {
        return item != this.select_series2;
      });
    },

    __selectSeries2Data() {
      return this.select_charts.filter((item) => {
        return item != this.select_series;
      });
    },
  },
  methods: {
    updateDateChartStatus() {
      this.$emit("filterReportByStatus", this.chartStatus);
    },
    dismissModal() {
      this.changeDialog = false;
    },
    async showLogs(item) {
      this.changeDialog = !this.changeDialog;
      this.mine = await item;
    },
    getSeries() {
      const selectedSeries = this.series.find((series) => {
        return series.name == this.select_series;
      });

      return selectedSeries;
    },
    getSeries2() {
      if (this.select_series2) {
        const selectedSeries = this.series.find((series) => {
          return series.name == this.select_series2;
        });

        return selectedSeries;
      } else return { name: "", data: [] };
    },

    selectSeries() {
      this.updateChart();
    },
    selectSeries2() {
      this.updateChart();
    },
    updateChart() {
      if (this.select_series == this.select_series2) {
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: "", data: [] },
        ]);
      } else
        this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
            labels: {
              format: "MMM dd yy",
            },
          },
          yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries().name=='Impressions' || this.getSeries().name=='Clicks'? 0 : 2,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries2().name=='Impressions' ||this.getSeries2().name=='Clicks'? 0 : 2
          },
        ],
        },
      };
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: this.getSeries2().name, data: this.getSeries2().data },
        ]);
      
    },
    generateChartData() {

      // const filteredReports = this.all_campaigns_reports.filter((report) => {
      //   return report;
      // });

      const filteredReports = this._graphData;

      this.monthlyImpressions = filteredReports.map((value) =>
        parseInt(value.total_impressions)
      );
      this.monthlySpend = filteredReports.map(
        (value) => parseInt((value.total_spend * 100) / 30) //added hack to return higher spend
      );
      this.monthlyClicks = filteredReports.map((value) =>
        parseInt(value.total_clicks)
      );
      this.monthlyAvgCTR = filteredReports.map((value) =>
        parseFloat(value.average_ctr)
      );

      this.chartDates = filteredReports.map((value) => value.date);

      this.series = [
        {
          name: "Impressions",
          data: this.monthlyImpressions,
        },
        {
          name: "Spend",
          data: this.monthlySpend,
        },
        {
          name: "Clicks",
          data: this.monthlyClicks,
        },
        {
          name: "CTR (%)",
          data: this.monthlyAvgCTR,
        },
      ];
      this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
            labels: {
              format: "MMM dd yy",
            },
          },
          yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.select_series=='Impressions' || this.select_series=='Clicks'? 0 : 2,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.select_series_2=='Impressions' || this.select_series_2=='Clicks'? 0 : 2
          },
        ],
        },
      };

      setTimeout(() => {
        this.selectSeries();
        this.selectSeries2();
      });
    },
  },
};
</script>
