export default {
    isLoading: false,
    auth: {
        access_token: null,
        email: null,
        user_id: null,
        name: null,
        roles: [],
        user_logo: null
    },

    client: null,
    clients: [],
    create_client: null,
    update_client: null,
    invitation: null,

    agency: null,
    agencies: [],
    single_agency: null,
    delete_agency: null,
    update_agency: null,
    all_agencies: [],

    permissions: [],
    permission: null,
    updated_permission: null,
    deleted_permission: null,

    roles: [],
    role: null,
    updated_role: null,
    deleted_role: null,

    users: [],
    deleted_user: null,
    user: null,
    updated_user: null,

    authenticated: false,
    authenticated_user: {},
    errors: null,
    notifications: [],

    qb_customers: []
}