<template>
  <div>
    <!-- feedback -->
    <v-dialog v-model="feedbackPopup" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon> mdi-message-alert-outline </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary py-2">
          <h5 class="white--text">Send Feedback</h5>
        </v-card-title>

        <v-col>
          <div class="ml-2 mr-2">
            <v-card-text>
              <h5 class="mb-2">Feedback type</h5>
              <v-select
                :items="product_item"
                label="Product"
                v-model="product.type"
                outlined
                dense
                hide-details="auto"
              ></v-select>
              <h4 class="my-3">Describe your feedback</h4>
              <v-textarea
                counter
                maxlength="255"
                full-width
                dense
                v-model="product.feedback"
                outlined
                hide-details="auto"
              ></v-textarea>
            </v-card-text>
          </div>
        </v-col>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="feedbackPopup = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="submitFeedback(product, user)"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- notifications -->

    <v-menu bottom left offset-y v-if="superAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-badge
            :content="count"
            :value="count"
            color="primary"
            bordered
            overlap
          >
            <v-icon> mdi-bell-outline </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <div v-if="data?.length">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>

        <v-list dense two-line>
          <v-list-item class="d-flex align-center py-0">
            <h3 class="secondary--text">Notifications</h3>
            <v-spacer></v-spacer>
            <v-chip
              class="ma-2"
              small
              color="green lighten-4"
              text-color="green--text darken-4"
            >
              <span class="font-weight-bold">{{ count }} New</span>
            </v-chip>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>

        <v-list two-line dense>
          <template v-for="(item, index) in data">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ notTitle(item) }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  notContent(item)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <v-list>
          <v-list-item>
            <v-btn
              @click="markNotificationAsRead"
              block
              depressed
              color="primary"
              class="mb-3"
            >
              Mark As Read
            </v-btn>
          </v-list-item>
        </v-list>
      </div>
      <div v-else>
        <v-list dense>
          <v-list desnse two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Go Grab Coffee 😁</v-list-item-title>
                <v-list-item-subtitle
                  >No unread notifications</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list>
      </div>
    </v-menu>

    <!-- <v-list-item>
      <v-list-item-avatar>
        <v-img :src="require('../../../assets/avatar.png')"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="font-weight-bold text-body-2">
          {{ user.name }}
        </v-list-item-title>
       
      </v-list-item-content>
    </v-list-item> -->

    <v-menu min-width="160" offset-y bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on" class="ml-1">
          <!-- <v-img :src="require('../../../assets/avatar.png')"></v-img> -->
          <v-avatar color="warning" size="32">
            <span class="white--text text-uppercase text-button">{{
              user.name.charAt(0)
            }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-list flat dense>
        <v-list-item-group color="primary" no-action>
          <v-list-item>
            <v-list-item-avatar size="48" color="warning">
              <!-- <v-img :src="require('../../../assets/avatar.png')"></v-img> -->
              <span class="white--text text-uppercase text-h6">{{
                user.name.charAt(0)
              }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-body-1 font-weight-bold mt-0">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/settings" link>
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Settings </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="menuClickAction(item.text)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data: () => ({
    items: [{ text: "Logout", icon: "mdi-logout" }],
    product_item: [
      "General System Issue",
      "Programmatic",
      "Facebook",
      "Opera",
      "Transsion",
      "Truecaller",
    ],
    product: {},
    search: null,
    name: localStorage.getItem("auth_name"),
    loading: false,
    feedbackPopup: false,
    count: 0,
    data: [],
    type: {
      a: "App\\Notifications\\CampaignUpdated",
      b: "App\\Notifications\\CampaignCreated",
    },
  }),
  mounted() {
    this.loadNotifications();
  },
  methods: {
    async submitFeedback(product, user) {
      try {
        this.$store.commit("makingApiRequest", true);
        emailjs.send(
          process.env.VUE_APP_SERVICE_ID,
          process.env.VUE_APP_TEMPLATE_ID,
          {
            email: user.email,
            name: user.name,
            product_type: product.type,
            feedback: product.feedback,
          },
          process.env.VUE_APP_EMAILJS_API_KEY
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Successfully Submitted Feedback",
          group: "success",
        });
        this.feedbackPopup = false;
      } catch (error) {
        this.$notify({
          title: error,
          group: "error",
        });
      }
    },
    async loadNotifications() {
      let body = {
        type: "unread",
      };

      try {
        this.loading = true;
        await this.$store.dispatch("getUserNotifications", body);
        this.loading = false;
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem updating notifications. Please try again later",
          group: "errors",
        });
        this.loading = false;
      }
    },
    notTitle(item) {
      if (item.type === this.type.a) {
        return "Campaign Updated ";
      } else if (item.type === this.type.b) {
        return "Campaign created ";
      }
    },
    notContent(item) {
      if (item.type === this.type.a) {
        return (
          item.data.name + " has updated " + item.data.campaign + " campaign"
        );
      } else if (item.type === this.type.b) {
        return (
          item.data.name +
          " has created a new campaign -  " +
          item.data.campaign
        );
      }
    },

    async markNotificationAsRead() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("markNotificationAsRead");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem updating Notification status. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    menuClickAction(text) {
      if (text == "Logout") {
        this.logoutRequest();
      } else if (text == "Settings") {
        this.$router.push({ name: "settings" });
      }
    },
    async logoutRequest() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("logout");
        this.$store.commit("clearAuthUser");

        //Clear Store
        this.$store.commit("facebook/resetFacebookState");
        this.$store.commit("dsp/resetDspState");
        this.$store.commit("billing/resetBillingState");
        this.$store.commit("resetGeneralState");

        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Logout Successful!",
          group: "success",
        });
        if (this.$route.params.from !== undefined) {
          this.$router.push({ name: this.params.from });
        } else {
          this.$router.push({ name: "login" });
        }
      } catch (error) {
        this.error = error;
        this.$store.commit("clearAuthUser");
        this.$store.commit("makingApiRequest", false);
        this.$router.push({ name: "login" });
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.authenticated_user;
    },
    notifications() {
      return this.$store.state.notifications.notifications;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    notifications() {
      this.count = this.notifications ? this.notifications?.data?.length : 0;
      this.data = this.notifications?.data;
    },
  },
};
</script>

<style scoped>
/* .top-nav {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 1px;
} */

/* .v-list-item__content {
  padding: 0 !important;
  display: block !important;
  flex: none;
} */
</style>
