<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      floating
      clipped
      app
      v-if="this.loggedIn"
    >
      <Nav />
    </v-navigation-drawer>

    <v-app-bar app color="white" clipped-left v-if="this.loggedIn" flat>
      <!-- <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> -->
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>

      <v-img
        alt="logo"
        class="shrink mx-3"
        contain
        :src="user_logo"
        transition="scale-transition"
        :width="_isSafaricom ? '110' : '90'"
      />

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        outlined
        dense
        hide-details
        v-if="superAdmin"
      ></v-text-field>
      <v-spacer></v-spacer>

      <topbar />
    </v-app-bar>

    <v-main class="v-main">
      <v-container fluid class="pa-6 vl-parent">
        <router-view :key="$router.path"></router-view>
        <notifications group="success" position="top center">
          <template slot="body" slot-scope="props">
            <div>
              <v-alert dense prominent dismissible text type="success"
                ><div v-html="props.item.title"></div
              ></v-alert>
            </div> </template
        ></notifications>
        <notifications group="errors" position="top center">
          <template slot="body" slot-scope="props">
            <div>
              <v-alert
                dense
                prominent
                dismissible
                icon="mdi-alert-circle"
                text
                type="error"
                ><div v-html="props.item.title"></div
              ></v-alert>
            </div>
          </template>
        </notifications>
        <loader />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Nav from "./components/Layouts/Nav.vue";
import Topbar from "./components/Layouts/Topbars/Topbar.vue";
import Loader from "./components/Loader.vue";

export default {
  name: "App",
  components: { Nav, Topbar, Loader },
  async mounted() {
    this.$route.name === "register" || this.$route.name === "login"
      ? this.$store.commit("setUserAndTokenOnRefresh")
      : null;

    this.handleIcon();
  },

  data: () => ({
    adAccountId: null,
    drawer: true,
    mini: false,
    search: null,
  }),
  methods: {
    handleIcon() {
      // accessing the link tag
      if (location.hostname == "desk.belvadigital.com") {
        const favicon = document.getElementById("favicon");
        favicon.href = "belva-favicon.svg";
      }

      //If hostname is safaricom.mediapal.net
      if (location.hostname == "safaricom.mediapal.net") {
        const favicon = document.getElementById("favicon");
        favicon.href = "safaricom-favicon.ico";
      }
    },
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    user_logo() {
      if (location.hostname == "dsp.tag.co.ke") {
        return "https://live.mediapal.net/ads/logos/tradedesk/TAG.png";
      }
      if (location.hostname == "desk.belvadigital.com") {
        return "https://live.mediapal.net/ads/logos/tradedesk/belva-dark.png";
      }

      if (location.hostname == "safaricom.mediapal.net") {
        return "saf-logo.png";
        // return "https://www.mediapal.net/brands/safaricom.png";
      }

      const logo = JSON.parse(localStorage.getItem("auth_user_logo"));

      if (logo != null && logo != "") {
        return logo;
      } else {
        return "https://live.mediapal.net/ads/logos/tradedesk/mediapal.png";
      }
    },
    _isSafaricom() {
      return location.hostname == "safaricom.mediapal.net" ? true : false;
    }
  },

};
</script>

<style lang="scss">

.v-btn {
  font-weight: bold;
}

.v-btn__content,
.v-select.v-input--dense .v-select__selection--comma {
  font-size: 0.8em;
}

.v-main__wrap {
  background-color: #f5f7fb;
}

.v-list-item__title {
  font-size: 0.8125rem;
  margin: 0.5rem 0;
}


.custom .v-pagination__navigation {
  height: 24px !important;
  width: 24px !important;
}

.custom .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}

.custom .v-pagination__item {
  height: 24px !important;
  min-width: 24px !important;
  font-size: 0.65rem !important;
}
.vue-notification-group {
  top: 16px !important;
}
.v-data-table {
  overflow-x: auto !important;
}
.v-application a {
  text-decoration: none;
}
</style>
