import init_state from "./state.js";

export default {
  resetDspState(state) {
    Object.assign(state, init_state);
  },
  setGoogleCreatives(state, payload) {
    state.google_creatives = payload.google_creatives;
  },
  setGoogleUserLists(state, payload) {
    state.user_lists = payload.user_lists;
  },
  setRemarketingTag(state, payload) {
    state.remarketing_tag = payload.remarketing_tag;
  },
  setNewUserList(state, payload) {
    state.user_list = payload.user_list;
  },
  setSingleUserList(state, payload) {
    state.single_user_list = payload.single_user_list;
  },
  setGlobalDiscoveryData(state, payload) {
    state.global_discovery_data = payload.global_discovery_data;
  },
  setoperaKenyaDiscoveryData(state, payload) {
    state.opera_kenya_discovery_data = payload.opera_kenya_discovery_data;
  },
  setoperaAngolaDiscoveryData(state, payload) {
    state.opera_angola_discovery_data = payload.opera_angola_discovery_data;
  },
  setoperaNigeriaDiscoveryData(state, payload) {
    state.opera_nigeria_discovery_data = payload.opera_nigeria_discovery_data;
  },
  setoperaGabonDiscoveryData(state, payload) {
    state.opera_gabon_discovery_data = payload.opera_gabon_discovery_data;
  },
  setoperaUgandaDiscoveryData(state, payload) {
    state.opera_uganda_discovery_data = payload.opera_uganda_discovery_data;
  },
  setoperaEthiopiaDiscoveryData(state, payload) {
    state.opera_ethiopia_discovery_data = payload.opera_ethiopia_discovery_data;
  },
  setoperaSenegalDiscoveryData(state, payload) {
    state.opera_senegal_discovery_data = payload.opera_senegal_discovery_data;
  },
  setoperaGlobalDiscoveryData(state, payload) {
    state.opera_global_discovery_data = payload.opera_global_discovery_data;
  },
  setoperaSouth_africaDiscoveryData(state, payload) {
    state.opera_south_africa_discovery_data =
      payload.opera_south_africa_discovery_data;
  },
  setgamingKenyaDiscoveryData(state, payload) {
    state.gaming_kenya_discovery_data = payload.gaming_kenya_discovery_data;
  },
  setgamingAngolaDiscoveryData(state, payload) {
    state.gaming_angola_discovery_data = payload.gaming_angola_discovery_data;
  },
  setgamingNigeriaDiscoveryData(state, payload) {
    state.gaming_nigeria_discovery_data = payload.gaming_nigeria_discovery_data;
  },
  setgamingGabonDiscoveryData(state, payload) {
    state.gaming_gabon_discovery_data = payload.gaming_gabon_discovery_data;
  },
  setgamingUgandaDiscoveryData(state, payload) {
    state.gaming_uganda_discovery_data = payload.gaming_uganda_discovery_data;
  },
  setgamingEthiopiaDiscoveryData(state, payload) {
    state.gaming_ethiopia_discovery_data = payload.gaming_ethiopia_discovery_data;
  },
  setgamingSenegalDiscoveryData(state, payload) {
    state.gaming_senegal_discovery_data = payload.gaming_senegal_discovery_data;
  },
  setgamingGlobalDiscoveryData(state, payload) {
    state.gaming_global_discovery_data = payload.gaming_global_discovery_data;
  },
  setgamingSouth_africaDiscoveryData(state, payload) {
    state.gaming_south_africa_discovery_data =
      payload.gaming_south_africa_discovery_data;
  },
  setctvKenyaDiscoveryData(state, payload) {
    state.ctv_kenya_discovery_data = payload.ctv_kenya_discovery_data;
  },
  setctvAngolaDiscoveryData(state, payload) {
    state.ctv_angola_discovery_data = payload.ctv_angola_discovery_data;
  },
  setctvNigeriaDiscoveryData(state, payload) {
    state.ctv_nigeria_discovery_data = payload.ctv_nigeria_discovery_data;
  },
  setctvGabonDiscoveryData(state, payload) {
    state.ctv_gabon_discovery_data = payload.ctv_gabon_discovery_data;
  },
  setctvUgandaDiscoveryData(state, payload) {
    state.ctv_uganda_discovery_data = payload.ctv_uganda_discovery_data;
  },
  setctvEthiopiaDiscoveryData(state, payload) {
    state.ctv_ethiopia_discovery_data = payload.ctv_ethiopia_discovery_data;
  },
  setctvSenegalDiscoveryData(state, payload) {
    state.ctv_senegal_discovery_data = payload.ctv_senegal_discovery_data;
  },
  setctvGlobalDiscoveryData(state, payload) {
    state.ctv_global_discovery_data = payload.ctv_global_discovery_data;
  },
  setctvSouth_africaDiscoveryData(state, payload) {
    state.ctv_south_africa_discovery_data =
      payload.ctv_south_africa_discovery_data;
  },
  settruecallerKenyaDiscoveryData(state, payload) {
    state.truecaller_kenya_discovery_data =
      payload.truecaller_kenya_discovery_data;
  },
  settruecallerAngolaDiscoveryData(state, payload) {
    state.truecaller_angola_discovery_data =
      payload.truecaller_angola_discovery_data;
  },
  settruecallerGabonDiscoveryData(state, payload) {
    state.truecaller_gabon_discovery_data =
      payload.truecaller_gabon_discovery_data;
  },
  settruecallerNigeriaDiscoveryData(state, payload) {
    state.truecaller_nigeria_discovery_data =
      payload.truecaller_nigeria_discovery_data;
  },
  settruecallerEthiopiaDiscoveryData(state, payload) {
    state.truecaller_ethiopia_discovery_data =
      payload.truecaller_ethiopia_discovery_data;
  },
  settruecallerUgandaDiscoveryData(state, payload) {
    state.truecaller_uganda_discovery_data =
      payload.truecaller_uganda_discovery_data;
  },
  settruecallerSenegalDiscoveryData(state, payload) {
    state.truecaller_senegal_discovery_data =
      payload.truecaller_senegal_discovery_data;
  },
  settruecallerGlobalDiscoveryData(state, payload) {
    state.truecaller_global_discovery_data =
      payload.truecaller_global_discovery_data;
  },
  settruecallerSouth_africaDiscoveryData(state, payload) {
    state.truecaller_south_africa_discovery_data =
      payload.truecaller_south_africa_discovery_data;
  },
  setTranssionKenyaForecast(state, payload) {
    state.transsion_kenya_forecast = payload.transsion_kenya_forecast;
  },
  setTranssionGabonForecast(state, payload) {
    state.transsion_gabon_forecast = payload.transsion_gabon_forecast;
  },
  settranssionKenyaDiscoveryData(state, payload) {
    state.transsion_kenya_discovery_data =
      payload.transsion_kenya_discovery_data;
  },
  settranssionAngolaDiscoveryData(state, payload) {
    state.transsion_angola_discovery_data =
      payload.transsion_angola_discovery_data;
  },
  settranssionEthiopiaDiscoveryData(state, payload) {
    state.transsion_ethiopia_discovery_data =
      payload.transsion_ethiopia_discovery_data;
  },
  settranssionUgandaDiscoveryData(state, payload) {
    state.transsion_uganda_discovery_data =
      payload.transsion_uganda_discovery_data;
  },
  settranssionGabonDiscoveryData(state, payload) {
    state.transsion_gabon_discovery_data =
      payload.transsion_gabon_discovery_data;
  },
  settranssionNigeriaDiscoveryData(state, payload) {
    state.transsion_nigeria_discovery_data =
      payload.transsion_nigeria_discovery_data;
  },
  settranssionSenegalDiscoveryData(state, payload) {
    state.transsion_senegal_discovery_data =
      payload.transsion_senegal_discovery_data;
  },
  settranssionGlobalDiscoveryData(state, payload) {
    state.transsion_global_discovery_data =
      payload.transsion_global_discovery_data;
  },
  settranssionSouth_africaDiscoveryData(state, payload) {
    state.transsion_south_africa_discovery_data =
      payload.transsion_south_africa_discovery_data;
  },
  setphoenixKenyaDiscoveryData(state, payload) {
    state.phoenix_kenya_discovery_data = payload.phoenix_kenya_discovery_data;
  },
  setphoenixAngolaDiscoveryData(state, payload) {
    state.phoenix_angola_discovery_data = payload.phoenix_angola_discovery_data;
  },
  setphoenixNigeriaDiscoveryData(state, payload) {
    state.phoenix_nigeria_discovery_data =
      payload.phoenix_nigeria_discovery_data;
  },
  setphoenixGabonDiscoveryData(state, payload) {
    state.phoenix_gabon_discovery_data =
      payload.phoenix_gabon_discovery_data;
  },
  setphoenixEthiopiaDiscoveryData(state, payload) {
    state.phoenix_ethiopia_discovery_data =
      payload.phoenix_ethiopia_discovery_data;
  },
  setphoenixUgandaDiscoveryData(state, payload) {
    state.phoenix_uganda_discovery_data =
      payload.phoenix_uganda_discovery_data;
  },
  setphoenixSenegalDiscoveryData(state, payload) {
    state.phoenix_senegal_discovery_data =
      payload.phoenix_senegal_discovery_data;
  },
  setphoenixGlobalDiscoveryData(state, payload) {
    state.phoenix_global_discovery_data = payload.phoenix_global_discovery_data;
  },
  setphoenixSouth_africaDiscoveryData(state, payload) {
    state.phoenix_south_africa_discovery_data =
      payload.phoenix_south_africa_discovery_data;
  },
  setKenyaDiscoveryData(state, payload) {
    state.kenya_discovery_data = payload.kenya_discovery_data;
  },
  setKenyaSitesAndApps(state, payload) {
    state.kenya_sitesAndApps = payload.kenya_sitesAndApps;
  },
  setNigeriaSitesAndApps(state, payload) {
    state.nigeria_sitesAndApps = payload.nigeria_sitesAndApps;
  },
  setAngolaSitesAndApps(state, payload) {
    state.angola_sitesAndApps = payload.angola_sitesAndApps;
  },
  setGabonSitesAndApps(state, payload) {
    state.gabon_sitesAndApps = payload.gabon_sitesAndApps;
  },
  setEthiopiaSitesAndApps(state, payload) {
    state.ethiopia_sitesAndApps = payload.ethiopia_sitesAndApps;
  },
  setUgandaSitesAndApps(state, payload) {
    state.uganda_sitesAndApps = payload.uganda_sitesAndApps;
  },
  setSenegalSitesAndApps(state, payload) {
    state.senegal_sitesAndApps = payload.senegal_sitesAndApps;
  },
  setGlobalSitesAndApps(state, payload) {
    state.global_sitesAndApps = payload.global_sitesAndApps;
  },
  setSouth_africaSitesAndApps(state, payload) {
    state.south_africa_sitesAndApps = payload.south_africa_sitesAndApps;
  },
  setSenegalDiscoveryData(state, payload) {
    state.senegal_discovery_data = payload.senegal_discovery_data;
  },
  setNigeriaDiscoveryData(state, payload) {
    state.nigeria_discovery_data = payload.nigeria_discovery_data;
  },
  setAngolaDiscoveryData(state, payload) {
    state.angola_discovery_data = payload.angola_discovery_data;
  },
  setUgandaDiscoveryData(state, payload) {
    state.uganda_discovery_data = payload.uganda_discovery_data;
  },
  setGabonDiscoveryData(state, payload) {
    state.gabon_discovery_data = payload.gabon_discovery_data;
  },
  setEthiopiaDiscoveryData(state, payload) {
    state.ethiopia_discovery_data = payload.ethiopia_discovery_data;
  },
  setSouth_africaDiscoveryData(state, payload) {
    state.south_africa_discovery_data = payload.south_africa_discovery_data;
  },
  setStaticDspData(state, payload) {
    state.static_dsp_data = payload.static_dsp_data;
  },
  setOperaKenyaForecast(state, payload) {
    state.opera_kenya_forecast = payload.opera_kenya_forecast;
  },
  setOperaSitesAndApps(state, payload) {
    state.opera_sitesAndApps = payload.opera_sitesAndApps;
  },
  setopera_kenya_SitesAndApps(state, payload) {
    state.opera_kenya_sitesAndApps = payload.opera_kenya_sitesAndApps;
  },
  setopera_gabon_SitesAndApps(state, payload) {
    state.opera_gabon_sitesAndApps = payload.opera_gabon_sitesAndApps;
  },
  setopera_ethiopia_SitesAndApps(state, payload) {
    state.opera_ethiopia_sitesAndApps = payload.opera_ethiopia_sitesAndApps;
  },
  setopera_angola_SitesAndApps(state, payload) {
    state.opera_angola_sitesAndApps = payload.opera_angola_sitesAndApps;
  },
  setopera_senegal_SitesAndApps(state, payload) {
    state.opera_senegal_sitesAndApps = payload.opera_senegal_sitesAndApps;
  },
  setopera_uganda_SitesAndApps(state, payload) {
    state.opera_uganda_sitesAndApps = payload.opera_uganda_sitesAndApps;
  },
  setopera_nigeria_SitesAndApps(state, payload) {
    state.opera_nigeria_sitesAndApps = payload.opera_nigeria_sitesAndApps;
  },
  setopera_south_africa_SitesAndApps(state, payload) {
    state.opera_south_africa_sitesAndApps =
      payload.opera_south_africa_sitesAndApps;
  },
  setopera_global_SitesAndApps(state, payload) {
    state.opera_global_sitesAndApps = payload.opera_global_sitesAndApps;
  },
  setgaming_kenya_SitesAndApps(state, payload) {
    state.gaming_kenya_sitesAndApps = payload.gaming_kenya_sitesAndApps;
  },
  setgaming_gabon_SitesAndApps(state, payload) {
    state.gaming_gabon_sitesAndApps = payload.gaming_gabon_sitesAndApps;
  },
  setgaming_ethiopia_SitesAndApps(state, payload) {
    state.gaming_ethiopia_sitesAndApps = payload.gaming_ethiopia_sitesAndApps;
  },
  setgaming_angola_SitesAndApps(state, payload) {
    state.gaming_angola_sitesAndApps = payload.gaming_angola_sitesAndApps;
  },
  setgaming_senegal_SitesAndApps(state, payload) {
    state.gaming_senegal_sitesAndApps = payload.gaming_senegal_sitesAndApps;
  },
  setgaming_uganda_SitesAndApps(state, payload) {
    state.gaming_uganda_sitesAndApps = payload.gaming_uganda_sitesAndApps;
  },
  setgaming_nigeria_SitesAndApps(state, payload) {
    state.gaming_nigeria_sitesAndApps = payload.gaming_nigeria_sitesAndApps;
  },
  setgaming_south_africa_SitesAndApps(state, payload) {
    state.gaming_south_africa_sitesAndApps =
      payload.gaming_south_africa_sitesAndApps;
  },
  setgaming_global_SitesAndApps(state, payload) {
    state.gaming_global_sitesAndApps = payload.gaming_global_sitesAndApps;
  },
  setctv_kenya_SitesAndApps(state, payload) {
    state.ctv_kenya_sitesAndApps = payload.ctv_kenya_sitesAndApps;
  },
  setctv_gabon_SitesAndApps(state, payload) {
    state.ctv_gabon_sitesAndApps = payload.ctv_gabon_sitesAndApps;
  },
  setctv_ethiopia_SitesAndApps(state, payload) {
    state.ctv_ethiopia_sitesAndApps = payload.ctv_ethiopia_sitesAndApps;
  },
  setgctv_angola_SitesAndApps(state, payload) {
    state.ctv_angola_sitesAndApps = payload.ctv_angola_sitesAndApps;
  },
  setctv_senegal_SitesAndApps(state, payload) {
    state.ctv_senegal_sitesAndApps = payload.ctv_senegal_sitesAndApps;
  },
  setctv_uganda_SitesAndApps(state, payload) {
    state.ctv_uganda_sitesAndApps = payload.ctv_uganda_sitesAndApps;
  },
  setctv_nigeria_SitesAndApps(state, payload) {
    state.ctv_nigeria_sitesAndApps = payload.ctv_nigeria_sitesAndApps;
  },
  setctv_south_africa_SitesAndApps(state, payload) {
    state.ctv_south_africa_sitesAndApps =
      payload.ctv_south_africa_sitesAndApps;
  },
  setctv_global_SitesAndApps(state, payload) {
    state.ctv_global_sitesAndApps = payload.ctv_global_sitesAndApps;
  },
  settruecaller_kenya_SitesAndApps(state, payload) {
    state.truecaller_kenya_sitesAndApps = payload.truecaller_kenya_sitesAndApps;
  },
  settruecaller_senegal_SitesAndApps(state, payload) {
    state.truecaller_senegal_sitesAndApps =
      payload.truecaller_senegal_sitesAndApps;
  },
  settruecaller_gabon_SitesAndApps(state, payload) {
    state.truecaller_gabon_sitesAndApps =
      payload.truecaller_gabon_sitesAndApps;
  },
  settruecaller_angola_SitesAndApps(state, payload) {
    state.truecaller_angola_sitesAndApps =
      payload.truecaller_angola_sitesAndApps;
  },
  settruecaller_uganda_SitesAndApps(state, payload) {
    state.truecaller_uganda_sitesAndApps =
      payload.truecaller_uganda_sitesAndApps;
  },
  settruecaller_ethiopia_SitesAndApps(state, payload) {
    state.truecaller_ethiopia_sitesAndApps =
      payload.truecaller_ethiopia_sitesAndApps;
  },
  settruecaller_nigeria_SitesAndApps(state, payload) {
    state.truecaller_nigeria_sitesAndApps =
      payload.truecaller_nigeria_sitesAndApps;
  },
  settruecaller_south_africa_SitesAndApps(state, payload) {
    state.truecaller_south_africa_sitesAndApps =
      payload.truecaller_south_africa_sitesAndApps;
  },
  settruecaller_global_SitesAndApps(state, payload) {
    state.truecaller_global_sitesAndApps =
      payload.truecaller_global_sitesAndApps;
  },
  settranssion_kenya_SitesAndApps(state, payload) {
    state.transsion_kenya_sitesAndApps = payload.transsion_kenya_sitesAndApps;
  },
  settranssion_uganda_SitesAndApps(state, payload) {
    state.transsion_uganda_sitesAndApps = payload.transsion_uganda_sitesAndApps;
  },
  settranssion_gabon_SitesAndApps(state, payload) {
    state.transsion_gabon_sitesAndApps = payload.transsion_gabon_sitesAndApps;
  },
  settranssion_angola_SitesAndApps(state, payload) {
    state.transsion_angola_sitesAndApps = payload.transsion_angola_sitesAndApps;
  },
  settranssion_ethiopia_SitesAndApps(state, payload) {
    state.transsion_ethiopia_sitesAndApps = payload.transsion_ethiopia_sitesAndApps;
  },
  settranssion_senegal_SitesAndApps(state, payload) {
    state.transsion_senegal_sitesAndApps =
      payload.transsion_senegal_sitesAndApps;
  },
  settranssion_nigeria_SitesAndApps(state, payload) {
    state.transsion_nigeria_sitesAndApps =
      payload.transsion_nigeria_sitesAndApps;
  },
  settranssion_south_africa_SitesAndApps(state, payload) {
    state.transsion_south_africa_sitesAndApps =
      payload.transsion_south_africa_sitesAndApps;
  },
  settranssion_global_SitesAndApps(state, payload) {
    state.transsion_global_sitesAndApps = payload.transsion_global_sitesAndApps;
  },
  setphoenix_kenya_SitesAndApps(state, payload) {
    state.phoenix_kenya_sitesAndApps = payload.phoenix_kenya_sitesAndApps;
  },
  setphoenix_gabon_SitesAndApps(state, payload) {
    state.phoenix_gabon_sitesAndApps = payload.phoenix_gabon_sitesAndApps;
  },
  setphoenix_senegal_SitesAndApps(state, payload) {
    state.phoenix_senegal_sitesAndApps = payload.phoenix_senegal_sitesAndApps;
  },
  setphoenix_angola_SitesAndApps(state, payload) {
    state.phoenix_angola_sitesAndApps = payload.phoenix_angola_sitesAndApps;
  },
  setphoenix_ethiopia_SitesAndApps(state, payload) {
    state.phoenix_ethiopia_sitesAndApps = payload.phoenix_ethiopia_sitesAndApps;
  },
  setphoenix_nigeria_SitesAndApps(state, payload) {
    state.phoenix_nigeria_sitesAndApps = payload.phoenix_nigeria_sitesAndApps;
  },
  setphoenix_uganda_SitesAndApps(state, payload) {
    state.phoenix_uganda_sitesAndApps = payload.phoenix_uganda_sitesAndApps;
  },
  setphoenix_south_africa_SitesAndApps(state, payload) {
    state.phoenix_south_africa_sitesAndApps =
      payload.phoenix_south_africa_sitesAndApps;
  },
  setphoenix_global_SitesAndApps(state, payload) {
    state.phoenix_global_sitesAndApps = payload.phoenix_global_sitesAndApps;
  },
  setphoenixKenyaForecast(state, payload) {
    state.phoenix_kenya_forecast = payload.phoenix_kenya_forecast;
  },
  setPhoenixSitesAndApps(state, payload) {
    state.phoenix_sitesAndApps = payload.phoenix_sitesAndApps;
  },
  setTruecallerKenyaForecast(state, payload) {
    state.truecaller_kenya_forecast = payload.truecaller_kenya_forecast;
  },
  setTruecallerGabonForecast(state, payload) {
    state.truecaller_gabon_forecast = payload.truecaller_gabon_forecast;
  },
  setTruecallerSitesAndApps(state, payload) {
    state.truecaller_sitesAndApps = payload.truecaller_sitesAndApps;
  },
  setTranssionSitesAndApps(state, payload) {
    state.transsion_sitesAndApps = payload.transsion_sitesAndApps;
  },
  setSitesAndApps(state, payload) {
    state.sitesAndApps = payload.sitesAndApps;
  },
  setInternationalDiscoveryData(state, payload) {
    state.international_discovery_data = payload.international_discovery_data;
  },
  setUpdatedUserList(state, payload) {
    state.updated_user_list = payload.updated_user_list;
  },
  setOpenUserList(state, payload) {
    state.open_user_list = payload.open_user_list;
  },
  setCloseUserList(state, payload) {
    state.close_user_list = payload.close_user_list;
  },
  setDspCampaigns(state, payload) {
    state.dsp_campaigns = payload.dsp_campaigns;
  },
  setDspCampaignStat(state, payload) {
    state.dsp_campaignStat = payload.dsp_campaignStat;
  },
  setDspCreatives(state, payload) {
    state.dsp_creatives = payload.dsp_creatives;
  },

  setBannerStatusesUpdate(state, payload) {
    state.update_banners_status = payload.update_banners_status;
  },

  setDspDropdownCampaigns(state, payload) {
    state.dsp_dropdown_campaigns = payload.dsp_dropdown_campaigns;
  },

  setDspCreative(state, payload) {
    state.dsp_creative = payload.dsp_creative;
  },

  setDisapprovedCreatives(state, payload) {
    state.disapproved_creatives = payload.disapproved_creatives;
  },
  setUnsubmittedCreatives(state, payload) {
    state.unsubmitted_creatives = payload.unsubmitted_creatives;
  },

  setDSPAdvertisers(state, payload) {
    state.dsp_advertisers = payload.dsp_advertisers;
  },
  setCampaignRules(state, payload) {
    state.dsp_campaign_rules = payload.dsp_campaign_rules;
  },
  setDspCampaign(state, payload) {
    state.dsp_campaign = payload.dsp_campaign;
  },
  setTargets(state, payload) {
    state.dsp_targets = payload.dsp_targets;
  },
  setGeolocations(state, payload) {
    state.geo_locations = payload.geo_locations;
  },
  setGeolocationsbyId(state, payload) {
    state.geo_locations_by_id = payload.geo_locations_by_id;
  },
  setIncludedGeolocationsbyId(state, payload) {
    state.included_geo_locations_by_id = payload.included_geo_locations_by_id;
  },
  setExcludedGeolocationsbyId(state, payload) {
    state.excluded_geo_locations_by_id = payload.excluded_geo_locations_by_id;
  },
  setGooglePretargetingConfigs(state, payload) {
    state.pretargeting_configs = payload.pretargeting_configs;
  },
  setSingleGooglePretarget(state, payload) {
    state.single_pretarget = payload.single_pretarget;
  },
  setNewGooglePretarget(state, payload) {
    state.new_pretarget = payload.new_pretarget;
  },
  setUpdatePretarget(state, payload) {
    state.update_pretarget = payload.update_pretarget;
  },
  setSuspendedPretarget(state, payload) {
    state.suspended_pretarget = payload.suspended_pretarget;
  },
  setActivatePretarget(state, payload) {
    state.activate_pretarget = payload.activate_pretarget;
  },
  setDeletePretarget(state, payload) {
    state.delete_pretarget = payload.delete_pretarget;
  },
  setTargetedApps(state, payload) {
    state.targeted_apps = payload.targeted_apps;
  },
  setRemoveTargetedApp(state, payload) {
    state.remove_apps = payload.remove_apps;
  },
  setNewTargetedPublishers(state, payload) {
    state.add_publishers = payload.add_publishers;
  },
  setRemoveTargetedPublishers(state, payload) {
    state.remove_publishers = payload.remove_publishers;
  },
  setTargetedSites(state, payload) {
    state.add_sites = payload.add_sites;
  },
  setremoveTargetedSites(state, payload) {
    state.remove_sites = payload.remove_sites;
  },
  setContentLabels(state, payload) {
    state.content_labels = payload.content_labels;
  },
  setMobileOS(state, payload) {
    state.mobile_os = payload.mobile_os;
  },
  setVerticalTargeting(state, payload) {
    state.vertical_targeting = payload.vertical_targeting;
  },
  setLanguages(state, payload) {
    state.languages = payload.languages;
  },
  setAgencies(state, payload) {
    state.agencies = payload.agencies;
  },
  setTargetingRule(state, payload) {
    state.targeting_rule = payload.targeting_rule;
  },
  setTargetingRules(state, payload) {
    state.targeting_rules = payload.targeting_rules;
  },
  setupdateTargetingRule(state, payload) {
    state.targeting_rule = payload.targeting_rule;
  },
  setSingleTargetingRule(state, payload) {
    state.single_target = payload.single_target;
  },
  setDeletedTarget(state, payload) {
    state.deleted_target = payload.deleted_target;
  },
  setpublisherProfiles(state, payload) {
    state.publisher_profiles = payload.publisher_profiles;
  },
  setRtbStandards(state, payload) {
    state.rtb_standards = payload.rtb_standards;
  },
  setCreateRtbRule(state, payload) {
    state.dsp_rtb_standard = payload.dsp_rtb_standard;
  },
  setUpdateRule(state, payload) {
    state.update_rtbRule = payload.update_rtbRule;
  },
  setsinglertbRule(state, payload) {
    state.single_rtbRule = payload.single_rtbRule;
  },
  setDeletedRule(state, payload) {
    state.deleted_rule = payload.deleted_rule;
  },
  setUploadDSPReport(state, payload) {
    state.upload_dsp_report_json = payload.upload_dsp_report_json;
  },
  setUploadLeadGen(state, payload) {
    state.upload_lead_gen_csv = payload.upload_lead_gen_csv;
  },
  setAllCampaignStatistics(state, payload) {
    state.all_campaigns_statistics = payload.all_campaigns_statistics;
  },
  setSingleCampaignStatistics(state, payload) {
    state.campaign_statistics = payload.campaign_statistics;
  },
  setHomeCampaignStatistics(state, payload) {
    state.home_campaign_statistics = payload.home_campaign_statistics;
  },
  setAllCampaignsReports(state, payload) {
    state.all_campaigns_reports = payload.all_campaigns_reports;
  },
  setAppCampaignStatistics(state, payload) {
    state.app_campaigns_statistics = payload.app_campaigns_statistics;
  },
  setTodayInsights(state, payload) {
    state.today_insights = payload.today_insights;
  },
  setLeadsCampaigns(state, payload) {
    state.leads_campaigns = payload.leads_campaigns;
  },
  setLeadsReport(state, payload) {
    state.report_link = payload.report_link;
  },
  clearReportLink(state) {
    state.report_link = null;
  },
  setCampaignLeads(state, payload) {
    state.campaign_leads = payload.campaign_leads;
  },
  setDSPCustomReport(state, payload){
    state.dsp_custom_report = payload.dsp_custom_report
  },
  setLastStatsUpdate(state, value) {
    state.last_stats_update = value;
  },
  setCampaignNotes(state, payload) {
    state.campaign_notes = payload.campaign_notes;
  },
  setCampaignScreenshots(state, payload) {
    state.campaign_screenshots = payload.campaign_screenshots;
  },
  setCampaignScreenshot(state, payload) {
    state.campaign_screenshot = payload.campaign_screenshot;
  },
  setEmptyResponse(state, payload) {
    state.response = payload.response;
  },
  setHistogramData(state, payload) {
    state.histogram_data = payload.histogram_data;
  },
  setActiveBidderCampaigns(state, payload) {
    state.active_bidder_campaigns = payload.active_bidder_campaigns;
  },
  setTopPublishers(state, payload) {
    state.top_publishers = payload.top_publishers;
  },
  setTopCarriers(state, payload) {
    state.top_carriers = payload.top_carriers;
  },
  setCityDistribution(state, payload){
    state.city_wise_distribution = payload.city_wise_distribution
  },
  setTopApps(state, payload){
    state.top_apps = payload.top_apps
  },
  setOSDistribution(state, payload){
    state.os_distribution = payload.os_distribution
  },
  setEnvironmentDistribution(state, payload){
    state.environment_distribution = payload.environment_distribution
  },
  setDeviceMakeDistribution(state, payload){
    state.device_make_distribution = payload.device_make_distribution
  },
  setDeviceModelDistribution(state, payload){
    state.device_model_distribution = payload.device_model_distribution
  },
  setConnectionTypes(state, payload){
    state.connection_types = payload.connection_types
  },
  setDeviceTypes(state, payload){
    state.device_types = payload.device_types
  },
  setBrowserDistribution(state, payload){
    state.browser_distribution = payload.browser_distribution
  },
  setCampaignReach(state, payload){
    state.campaign_reach = payload.campaign_reach
  }
};
