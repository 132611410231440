export default {
  google_creatives: null,
  user_lists: [],
  remarketing_tag: null,
  user_list: null,
  single_user_list: null,
  updated_user_list: null,
  open_user_list: null,
  close_user_list: null,
  dsp_campaigns: [],
  dsp_campaignStat: [],
  dsp_dropdown_campaigns: [],
  last_stats_update: null,
  dsp_creatives: null,
  dsp_creative: null,
  disapproved_creatives: null,
  unsubmitted_creatives: null,
  dsp_advertisers: [],
  dsp_campaign_rules: [],
  dsp_campaign: null,
  dsp_targets: [],
  agencies: [],
  pretargeting_configs: null,
  single_pretarget: null,
  new_pretarget: null,
  update_pretarget: null,
  update_banners_status: null,
  suspended_pretarget: null,
  activate_pretarget: null,
  delete_pretarget: null,
  publisher_profiles: null,
  targeted_apps: null,
  remove_apps: null,
  add_publishers: null,
  remove_publishers: null,
  remove_sites: null,
  mobile_os: [],
  vertical_targeting: [],
  languages: [],
  content_labels: [],
  geo_locations: [],
  included_geo_locations_by_id: [],
  excluded_geo_locations_by_id: [],
  targeting_rule: null,
  single_target: null,
  deleted_target: null,
  rtb_standards: [],
  update_rtbRule: null,
  deleted_rule: null,
  single_rtbRule: null,
  kenya_discovery_data: {},
  angola_discovery_data: {},
  senegal_discovery_data: {},
  uganda_discovery_data: {},
  ethiopia_discovery_data: {},
  south_africa_discovery_data: {},
  nigeria_discovery_data: {},
  gabon_discovery_data: {},
  global_discovery_data: {},
  opera_kenya_discovery_data: {},
  opera_gabon_discovery_data: {},
  opera_angola_discovery_data: {},
  opera_ethiopia_discovery_data: {},
  opera_uganda_discovery_data: {},
  opera_senegal_discovery_data: {},
  opera_south_africa_discovery_data: {},
  opera_nigeria_discovery_data: {},
  opera_global_discovery_data: {},
  truecaller_kenya_discovery_data: {},
  truecaller_angola_discovery_data: {},
  truecaller_gabon_discovery_data: {},
  truecaller_ethiopia_discovery_data: {},
  truecaller_senegal_discovery_data: {},
  truecaller_uganda_discovery_data: {},
  truecaller_south_africa_discovery_data: {},
  truecaller_nigeria_discovery_data: {},
  truecaller_global_discovery_data: {},
  transsion_kenya_discovery_data: {},
  transsion_gabon_discovery_data: {},
  transsion_angola_discovery_data: {},
  transsion_ethiopia_discovery_data: {},
  transsion_uganda_discovery_data: {},
  transsion_senegal_discovery_data: {},
  transsion_south_africa_discovery_data: {},
  transsion_nigeria_discovery_data: {},
  transsion_global_discovery_data: {},
  phoenix_kenya_discovery_data: {},
  phoenix_gabon_discovery_data: {},
  phoenix_angola_discovery_data: {},
  phoenix_ethiopia_discovery_data: {},
  phoenix_uganda_discovery_data: {},
  phoenix_senegal_discovery_data: {},
  phoenix_south_africa_discovery_data: {},
  phoenix_nigeria_discovery_data: {},
  phoenix_global_discovery_data: {},
  gaming_kenya_discovery_data: {},
  gaming_gabon_discovery_data: {},
  gaming_angola_discovery_data: {},
  gaming_ethiopia_discovery_data: {},
  gaming_uganda_discovery_data: {},
  gaming_senegal_discovery_data: {},
  gaming_south_africa_discovery_data: {},
  gaming_nigeria_discovery_data: {},
  gaming_global_discovery_data: {},
  ctv_kenya_discovery_data: {},
  ctv_gabon_discovery_data: {},
  ctv_angola_discovery_data: {},
  ctv_ethiopia_discovery_data: {},
  ctv_uganda_discovery_data: {},
  ctv_senegal_discovery_data: {},
  ctv_south_africa_discovery_data: {},
  ctv_nigeria_discovery_data: {},
  ctv_global_discovery_data: {},
  sitesAndApps: [],
  kenya_sitesAndApps: [],
  angola_sitesAndApps: [],
  gabon_sitesAndApps: [],
  senegal_sitesAndApps: [],
  ethiopia_sitesAndApps: [],
  uganda_sitesAndApps: [],
  nigeria_sitesAndApps: [],
  south_africa_sitesAndApps: [],
  global_sitesAndApps: [],
  opera_kenya_sitesAndApps: [],
  opera_gabon_sitesAndApps: [],
  opera_angola_sitesAndApps: [],
  opera_ethiopia_sitesAndApps: [],
  opera_uganda_sitesAndApps: [],
  opera_senegal_sitesAndApps: [],
  opera_nigeria_sitesAndApps: [],
  opera_south_africa_sitesAndApps: [],
  opera_global_sitesAndApps: [],
  gaming_kenya_sitesAndApps: [],
  gaming_gabon_sitesAndApps: [],
  gaming_angola_sitesAndApps: [],
  gaming_ethiopia_sitesAndApps: [],
  gaming_uganda_sitesAndApps: [],
  gaming_senegal_sitesAndApps: [],
  gaming_nigeria_sitesAndApps: [],
  gaminng_south_africa_sitesAndApps: [],
  gaming_global_sitesAndApps: [],
  ctv_kenya_sitesAndApps: [],
  ctv_gabon_sitesAndApps: [],
  ctv_angola_sitesAndApps: [],
  ctv_ethiopia_sitesAndApps: [],
  ctv_uganda_sitesAndApps: [],
  ctv_senegal_sitesAndApps: [],
  ctv_nigeria_sitesAndApps: [],
  ctv_south_africa_sitesAndApps: [],
  ctv_global_sitesAndApps: [],
  phoenix_kenya_sitesAndApps: [],
  phoenix_gabon_sitesAndApps: [],
  phoenix_angola_sitesAndApps: [],
  phoenix_ethiopia_sitesAndApps: [],
  phoenix_uganda_sitesAndApps: [],
  phoenix_senegal_sitesAndApps: [],
  phoenix_nigeria_sitesAndApps: [],
  phoenix_south_africa_sitesAndApps: [],
  phoenix_global_sitesAndApps: [],
  international_discovery_data: null,
  upload_dsp_report_json: [],
  upload_lead_gen_csv: null,
  all_campaigns_statistics: [],
  campaign_statistics: [],
  home_campaign_statistics: null,
  all_campaigns_reports: [],
  app_campaigns_statistics: [],
  today_insights: null,
  opera_kenya_forecast: {},
  opera_sitesAndApps: [],
  truecaller_kenya_forecast: {},
  truecaller_sitesAndApps: [],
  transsion_kenya_forecast: {},
  transsion_sitesAndApps: [],
  phoenix_sitesAndApps: [],
  phoenix_kenya_forecast: {},
  leads_campaigns: [],
  campaign_leads: [],
  report_leads: [],
  report_link: null,
  static_dsp_data: {},
  campaign_notes: [],
  campaign_screenshots: [],
  campaign_screenshot: [],
  response: null,
  dsp_custom_report:  null,
  histogram_data: [],
  active_bidder_campaigns: [],
  top_publishers: [],
  top_carriers: [],
  city_wise_distribution: [],
  top_apps:[],
  os_distribution: [],
  environment_distribution: [],
  connection_types: [],
  device_types: [],
  device_make_distribution: [],
  device_model_distribution: [],
  browser_distribution: [],
  campaign_reach: [],
};
