//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const compactNumber = (number) => {
  return Intl.NumberFormat("en", { notation: "compact" }).format(number);
};

export const usCurrencyFormatter = (number) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};
export const usCurrencyFormatterCompact = (number) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    style: "currency",
    currency: "USD",
  }).format(number);
};
