<template>
  <v-row class="mt-7" v-if="superAdmin">
    <v-col cols="6">
      <v-card outlined>
        <v-card-title class="py-2">
          <h5>Disapproved Creatives</h5>
          <v-spacer></v-spacer>
          <v-btn
            v-if="disapproved_banners.data"
            color="secondary"
            small
            elevation="0"
            @click="DisapprovedSubmitMultiple()"
            >Resubmit All</v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-simple-table fixed-header v-if="disapproved_banners.data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Banner</th>
                <th class="text-left">ID</th>
                <th class="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in disapproved_banners.data"
                :key="item.banner_id"
              >
                <td>
                  <span class="font-weight-bold">{{
                    item.banner ? item.banner.name : item.banner_id
                  }}</span
                  ><br />
                  <span class="red--text text--lighten-1 text-lowercase">{{
                    disapprovedReason(item).replaceAll("_", " ")
                  }}</span>
                </td>
                <td>{{ item.banner_id }}</td>
                <td class="text-right">
                  <v-tooltip top color="grey lighten-1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="DisapprovedSubmit(item.banner_id)"
                      >
                        <v-icon small>mdi-arrow-u-right-top</v-icon>
                      </v-btn>
                    </template>
                    <span> Resubmit </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>
          <p class="text-center pt-4">There are no disapproved creatives</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between align-center pa-4">
          <span class="caption"
            >Page {{ disapprovedPagination.page }} of
            {{ disapprovedPagination.totalItems }}</span
          >

          <v-pagination
            v-model="disapprovedPagination.page"
            :length="disapprovedPagination.totalItems"
            color="primary"
            class="custom"
            @input="handleDisapprovedPageChange"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="6">
      <v-card outlined>
        <v-card-title class="py-2">
          <h5>Unsubmitted Creatives</h5>
          <v-spacer></v-spacer>
          <v-btn
            v-if="unsubmitted_banners.data"
            color="secondary"
            small
            elevation="0"
            @click="UnsubmittedSubmitMultiple()"
            >Submit All</v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-simple-table v-if="unsubmitted_banners.data" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Banner</th>
                <th class="text-left">ID</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in unsubmitted_banners.data"
                :key="item.banner_id"
              >
                <td>{{ item.banner.name }}</td>
                <td>{{ item.banner_id }}</td>
                <td>
                  <v-tooltip top color="grey lighten-1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="handleSubmit(item.banner_id)"
                      >
                        <v-icon small>mdi-send</v-icon>
                      </v-btn>
                    </template>
                    <span> Submit </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>
          <p class="text-center pt-4">There are no unsubmitted creatives</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between align-center pa-4">
          <span class="caption"
            >Page {{ pagination.page }} of {{ pagination.totalItems }}</span
          >
          <v-pagination
            v-model="pagination.page"
            :length="pagination.totalItems"
            color="primary"
            :total-visible="5"
            class="custom"
            @input="handleUnsubmittedPageChange"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    disapproved_banners: [],
    unsubmitted_banners: [],
    type: "",
    pagination: {
      page: 1,
      rowsPerPage: 5,
      totalItems: 0,
    },
    disapprovedPagination: {
      page: 1,
      rowsPerPage: 5,
      totalItems: 0,
    },
  }),
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  props: {
    dsp_creatives: Object,
    disapproved_creatives: Object,
    unsubmitted_creatives: Object,
  },
  methods: {
    handleUnsubmittedPageChange(value) {
      this.pagination.page = value;
      this.$emit("triggerUnsubPageChange", value);
    },
    handleDisapprovedPageChange(value) {
      this.disapprovedPagination.page = value;
      this.$emit("triggerDisapprovedPageChange", value);
    },

    async handleSubmit(banner_id) {
      let bannertoSubmit = this.unsubmitted_creatives.data.filter(
        (element) => element.id === banner_id
      );
      let body = { id: banner_id };
      if (Object.keys(bannertoSubmit[0]).includes("htmltemplate")) {
        body.type = "HTML";
      }
      this.$emit("submitUnsubmittedBanner", body);
    },

    async DisapprovedSubmit(id) {
      let body = {
        id,
      };
      this.$emit("submitDisapprovedBanner", body);
    },

    async DisapprovedSubmitMultiple() {
      const disapproved_banner_ids = this.disapproved_banners.map(
        (banner) => banner.banner_id
      );

      let body = {
        banner_ids: disapproved_banner_ids,
      };

      this.$emit("submitDisapprovedMultiple", body);
    },

    async UnsubmittedSubmitMultiple() {
      const unsubmitted_banner_ids = this.unsubmitted_creatives.data.map(
        (banner) => banner.id
      );
      this.$emit("submitUnsubmittedMultiple", unsubmitted_banner_ids);
    },

    disapprovedReason(item) {
      if (item.network_policy_compliance_status == "DISAPPROVED") {
        return item.network_policy_topics;
      } else if (item.platform_policy_compliance_status == "DISAPPROVED") {
        return item.platform_policy_topics;
      }
    },
  },

  watch: {
    disapproved_creatives() {
      this.disapproved_banners = this.disapproved_creatives
        ? this.disapproved_creatives
        : [];
      this.disapprovedPagination.page = this.disapproved_creatives.current_page;
      this.disapprovedPagination.totalItems =
        this.disapproved_creatives.last_page;
    },

    unsubmitted_creatives() {
      this.unsubmitted_banners = this.unsubmitted_creatives
        ? this.unsubmitted_creatives
        : [];
      this.pagination.page = this.unsubmitted_creatives.current_page;
      this.pagination.totalItems = this.unsubmitted_creatives.last_page;
    },
  },
};
</script>




