import api from "../../../api"

export default {
    //Reset State 
    resetBillingState({ commit }) {
        commit("resetBillingState")
    },

    //Customers
    fetchCustomers({ commit }) {
        return api.billing.getCustomers().then(response => {
            const payload = {};
            payload.customers = response;
            commit("setCustomers", payload);
        });
    },

    fetchInvoicesOverview({ commit }) {
        return api.billing.getInvoicesOverview().then(response => {
            const payload = {};
            payload.invoices_overview = response;
            commit("setInvoicesOverview", payload);
        });
    },

    getCustomer({ commit }, id) {
        return api.billing.getCustomer(id).then(response => {
            const payload = {};
            payload.customer = response;
            commit("setCustomer", payload);
        });
    },

    createCustomer({ commit }, body) {
        return api.billing.createCustomer(body).then(response => {
            const payload = {};
            payload.customer = response;
            commit("setCustomer", payload);
        });
    },

    updateCustomer({ commit }, body) {
        return api.billing.updateCustomer(body).then(response => {
            const payload = {};
            payload.customer = response;
            commit("setCustomer", payload);
        });
    },

    deleteCustomer({ commit }, id) {
        return api.billing.deleteCustomer(id).then(response => {
            const payload = response;
            payload.customer = null;
            commit("setCustomer", payload);
        });
    },

    //Invoices
    fetchInvoices({ commit }, body) {
        return api.billing.getInvoices(body).then(response => {
            const payload = {};
            payload.invoices = response;
            commit("setInvoices", payload);
        });
    },

    getInvoice({ commit }, id) {
        return api.billing.getInvoice(id).then(response => {
            const payload = {};
            payload.invoice = response;
            commit("setInvoice", payload);
        });
    },

    createInvoice({ commit }, body) {
        return api.billing.createInvoice(body).then(response => {
            const payload = {};
            payload.invoice = response;
            commit("setInvoice", payload);
        });
    },

    updateInvoice({ commit }, body) {
        return api.billing.updateInvoice(body).then(response => {
            const payload = {};
            payload.invoice = response;
            commit("setInvoice", payload);
        });
    },

    deleteInvoice({ commit }, id) {
        return api.billing.deleteInvoice(id).then(response => {
            const payload = response;
            payload.invoice = null;
            commit("setInvoice", payload);
        });
    },

    //POs
    fetchPOs({ commit }) {
        return api.billing.getPOs().then(response => {
            const payload = {};
            payload.purchase_orders = response;
            commit("setPurchaseOrders", payload);
        });
    },

    getPO({ commit }, id) {
        return api.billing.getPO(id).then(response => {
            const payload = {};
            payload.purchase_order = response;
            commit("setPurchaseOrder", payload);
        });
    },

    createPurchaseOrder({commit}, body){
        return api.billing.createPurchaseOrder(body).then(response => {
            const payload = {};
            payload.purchase_order = response;
            commit("setPurchaseOrder", payload);
        });
    },

    updatePurchaseOrder({commit}, body){
        return api.billing.updatePurchaseOrder(body).then(response => {
            const payload = {};
            payload.purchase_order = response;
            commit("setPurchaseOrder", payload);
        });
    },

    deletePurchaseOrder({commit}, id){
        return api.billing.deletePurchaseOrder(id).then(response => {
            const payload = response;
            payload.purchase_order = null;
            commit("setPurchaseOrder", payload);
        });
    },
};