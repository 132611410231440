<template>
  <div>
    <v-navigation-drawer v-model="dialog" fixed temporary right width="50%">
      <v-card flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h6 class="text-uppercase">Change Log</h6>
          <v-btn icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          <h4>
            {{ item.name }}
          </h4>
          <span class="overline">
            <v-icon small class="mb-1"> mdi-clock-outline </v-icon>
            Start:
            {{ item.start_date }}
            | End:
            {{ item.end_date }}
          </span>
          <!-- <div class="caption">Creation Date: {{ new Date() }}</div> -->
        </v-card-subtitle>

        <action-log-table :headers="headers" :items="logs"></action-log-table>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ActionLogTable from "../Tables/ActionLogTable.vue";
export default {
  components: { ActionLogTable },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logs: [],
      headers: [
        { text: "Date", value: "date", width: "20%" },
        {
          text: "Field Name",
          value: "field",
          width: "20%",
        },
        {
          text: "Old",
          value: "old",
          width: "20%",
        },
        {
          text: "New",
          value: "new",
          width: "20%",
        },
        { text: "Changed By", value: "user", width: "20%" },
      ],
    };
  },
  watch: {
    item() {
      this.logs = this.item.log ? [...this.item.log] : [];
    },
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>
