<template>
  <div>
    <v-tabs
      v-model="tab"
      slider-color="primary"
      background-color="#F5F7FB"
      class="mb-5"
      style="border-bottom: 1px solid #e0e0e0"
    >
      <!-- Top bar code -->
      <TopBar :tabs="tabs" :count="__count"></TopBar>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="active">
        <campaigns-table
          @deleteCampaign="setDeleteCampaign($event)"
          @filterByAgency="setFilterByAgency($event)"
          @filterByAdvertiser="setfilterByAdvertiser($event)"
          @filterBySearch="filterBySearch($event)"
          @triggerPageChange="setPageNumber($event)"
          @filterParams="getCampaigns($event)"
          @submitBanners="submitCampaignBanners($event)"
          @updateCampaignStatus="updateCampaignStatus($event)"
          @filterGraphByCampaign="filterGraphByCampaign($event)"
          @resetCampaignsFilter="resetCampaignsFilter($event)"
          :headers="computedHeaders"
          :campaigns="__activecampaigns"
          ref="modal"
          :loading="loading"
          :create_campaign="__channel"
        />
      </v-tab-item>
      <v-tab-item value="review">
        <campaigns-table
          @deleteCampaign="setDeleteCampaign($event)"
          @filterByAgency="setFilterByAgency($event)"
          @filterByAdvertiser="setfilterByAdvertiser($event)"
          @filterBySearch="filterBySearch($event)"
          @triggerPageChange="setPageNumber($event)"
          @filterParams="getCampaigns($event)"
          @submitBanners="submitCampaignBanners($event)"
          @updateCampaignStatus="updateCampaignStatus($event)"
          @filterGraphByCampaign="filterGraphByCampaign($event)"
          @resetCampaignsFilter="resetCampaignsFilter($event)"
          :headers="computedHeaders"
          :campaigns="__reviewcampaigns"
          :loading="loading"
          ref="modal"
          :create_campaign="__channel"
        />
      </v-tab-item>
      <v-tab-item value="paused">
        <campaigns-table
          @deleteCampaign="setDeleteCampaign($event)"
          @filterByAgency="setFilterByAgency($event)"
          @filterByAdvertiser="setfilterByAdvertiser($event)"
          @filterBySearch="filterBySearch($event)"
          @triggerPageChange="setPageNumber($event)"
          @filterParams="getCampaigns($event)"
          @submitBanners="submitCampaignBanners($event)"
          @updateCampaignStatus="updateCampaignStatus($event)"
          @filterGraphByCampaign="filterGraphByCampaign($event)"
          @resetCampaignsFilter="resetCampaignsFilter($event)"
          :headers="computedHeaders"
          :campaigns="__pausedcampaigns"
          :loading="loading"
          ref="modal"
        />
      </v-tab-item>
      <v-tab-item value="completed">
        <campaigns-table
          @deleteCampaign="setDeleteCampaign($event)"
          @filterByAgency="setFilterByAgency($event)"
          @filterByAdvertiser="setfilterByAdvertiser($event)"
          @filterBySearch="filterBySearch($event)"
          @triggerPageChange="setPageNumber($event)"
          @filterParams="getCampaigns($event)"
          @submitBanners="submitCampaignBanners($event)"
          @updateCampaignStatus="updateCampaignStatus($event)"
          @filterGraphByCampaign="filterGraphByCampaign($event)"
          @resetCampaignsFilter="resetCampaignsFilter($event)"
          :headers="computedHeaders"
          :campaigns="__completedcampaigns"
          :loading="loading"
          ref="modal"
          :create_campaign="__channel"
        />
      </v-tab-item>

      <v-tab-item value="all">
        <campaigns-table
          @deleteCampaign="setDeleteCampaign($event)"
          @filterByAgency="setFilterByAgency($event)"
          @filterByAdvertiser="setfilterByAdvertiser($event)"
          @filterBySearch="filterBySearch($event)"
          @triggerPageChange="setPageNumber($event)"
          @filterParams="getCampaigns($event)"
          @submitBanners="submitCampaignBanners($event)"
          @updateCampaignStatus="updateCampaignStatus($event)"
          @filterGraphByCampaign="filterGraphByCampaign($event)"
          @resetCampaignsFilter="resetCampaignsFilter($event)"
          :headers="computedHeaders"
          :campaigns="__allcampaigns"
          ref="modal"
          :loading="loading"
          :create_campaign="__channel"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CampaignsTable from "../../components/Tables/DSPCampaignsTable.vue";
import TopBar from "../../components/Layouts/Topbars/DSPTableTabs.vue";
import { dspTablesMixin } from "../../mixins/dspTablesMixin";

export default {
  mixins: [dspTablesMixin],
  components: {
    CampaignsTable,
    TopBar,
  },
};
</script>
