import axios from "axios";

class Client {
  constructor(requiresAuth = false) {
    this.requiresAuth = requiresAuth;
    this.createClient();
  }
  createClient() {
    let app_url = null;
    if (location.hostname == 'tradedesk.mediapal.net' || location.hostname == 'desk.belvadigital.com' || location.hostname == "safaricom.mediapal.net") { //prod via tradedesk
      app_url = process.env.VUE_APP_PROD_API_URL
    }
    else if (location.hostname == 'a0c991f71231d47b0aaeffeeceb70649-112578480.eu-west-1.elb.amazonaws.com') { //prod via aws url
      app_url = process.env.VUE_APP_PROD_API_URL
    } 
    else { //localhost and dev environments
      app_url = process.env.VUE_APP_DEV_API_URL
    }
    this.client = axios.create({
      baseURL: app_url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
  }

  registerIntercept(callback) {
    return callback(this.client);
  }

  processSuccess(response) {
    return response.data;
  }

  processFailure(error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }

  get(url, query, config) {
    this.createClient();
    return this.client
      .get(url, query, config)
      .then(this.processSuccess).catch(this.processFailure);
  }

  post(url, data, config) {
    this.createClient();
    return this.client
      .post(url, data, config)
      .then(this.processSuccess).catch(this.processFailure);
  }

  put(url, data, config) {
    this.createClient();
    return this.client
      .put(url, data, config)
      .then(this.processSuccess).catch(this.processFailure);
  }

  delete(url, data, config) {
    this.createClient();
    return this.client
      .delete(url, data, config)
      .then(this.processSuccess).catch(this.processFailure);
  }
}

export default Client;
