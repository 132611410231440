import { mapState, mapGetters } from "vuex";
export const dspTablesMixin = {
  data: () => ({
    loading: true,
    paused_campaigns: [],
    all_campaigns: [],
    completed_campaigns: [],
    in_review: [],
    active_campaigns: [],
    selected_agency: null,
    selected_advertiser: null,
    fetched_advertisers: [],
    tabs: [
      ["Active", "active", "mdi-play"],
      ["In Review", "review", "mdi-file-edit-outline"],
      ["Paused", "paused", "mdi-pause"],
      ["Completed", "completed", "mdi-check-all"],
      ["All", "all", "mdi-format-list-bulleted"],
    ],
    btns: ["Add Campaign"],
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      {
        text: "🚀",
        align: "center",
        value: "toggle_status",
        width: "100px"
      },
      {
        text: "Campaign Name",
        align: "start",
        value: "name",
        width: "280px",
      },
      {
        text: "Status",
        value: "activity_status",
        width: "90px",
      },
      {
        text: "State",
        value: "status",
        width: "90px",
      },
      {
        text: "Agency",
        value: "agency",
        width: "140px",
      },
      {
        text: "Advertiser",
        value: "advertiser",
        width: "140px",
      },
      { text: "Objective", value: "campaign_objective", width: "120px" },
      { text: "Type", value: "campaign_type", width: "120px" },
      { text: "Bid Strategy", value: "bid_strategy", width: "130px" },
      {
        text: "Flight Pacing",
        value: "pacing",
        width: "180px",
      },
      {
        text: "Daily Pacing",
        value: "daily_pacing",
        width: "180px",
      },
      { text: "Start Date", value: "start_date", width: "130px" },
      { text: "End Date", value: "end_date", width: "130px" },
      { text: "Days Left", value: "days_left", width: "80px" },
      { text: "Budget", value: "budget", width: "120px" },
      { text: "Budget Remaining", value: "budget_remaining", width: "120px" },
      { text: "Spend Yesterday", value: "spend_yesterday", width: "120px" },

      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
    error: null,
    search: "",
    client: "",
  }),
  props: {},
  watch: {},
  mounted() {},
  created() {
    //call advertisers only once before component mounts
    this.getCampaigns();
  },

  computed: {
    //return current active tab
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    // //return state
    // dsp_campaigns() {
    //   return this.$store.state.dsp.dsp_campaigns;
    // },

    ...mapState({
      dsp_campaigns: (state) => state.dsp.dsp_campaigns,
    }),
    ...mapGetters({
      superAdmin: "superAdmin",
      getCampaignsByChannel: "dsp/getCampaignsByChannel",
    }),
    // superAdmin() {
    //   return this.$store.getters.superAdmin;
    // },
    //get pagination data

    //return table headers and hide if user role in not admin
    computedHeaders() {
      // hide advertiser && daily pacing from clientview
      return this.headers.filter((el) => {
        if (!this.superAdmin) {
          return (
            el.value !== "agency" &&
            el.value !== "advertiser" &&
            el.value !== "daily_pacing" &&
            el.value != "spend_yesterday" &&
            el.value !== "status" &&
            el.value !== "toggle_status"
          );
        } else return el;
      });
    },
    //all campaigns
    __allcampaigns() {
      return this.getCampaignsByChannel(this.__channel.app);
    },
    //all campaigns
    __activecampaigns() {
      return this.getCampaignsByChannel(this.__channel.app).filter(
        (campaign) => campaign.activity_status === "Active"
      );
    },
    //all campaigns
    __completedcampaigns() {
      return this.getCampaignsByChannel(this.__channel.app).filter(
        (campaign) => campaign.activity_status === "Completed"
      );
    },
    //all campaigns
    __pausedcampaigns() {
      return this.getCampaignsByChannel(this.__channel.app).filter(
        (campaign) => campaign.activity_status === "Paused"
      );
    },
    //all campaigns
    __reviewcampaigns() {
      return this.getCampaignsByChannel(this.__channel.app).filter(
        (campaign) => campaign.activity_status === "In Review"
      );
    },

    //count
    __count() {
      return {
        all: this.__allcampaigns?.length.toString(),
        active: this.__activecampaigns?.length.toString(),
        completed: this.__completedcampaigns?.length.toString(),
        paused: this.__pausedcampaigns?.length.toString(),
        review: this.__reviewcampaigns?.length.toString(),
      };
    },

    //determine if its programmatic or app campaigns
    __channel() {
      const currentRoute = this.$route.name;

      if (currentRoute === "campaigns" || currentRoute === "home") {
        return {
          app: "programmatic",
          create: "new-campaign",
        };
      }
      if (currentRoute === "opera-campaigns" || currentRoute === "Opera home") {
        return {
          app: "opera",
          create: "new-opera-campaign",
        };
      }
      if (
        currentRoute === "truecaller-campaigns" ||
        currentRoute === "Truecaller"
      ) {
        return {
          app: "truecaller",
          create: "new-truecaller-campaign",
        };
      }
      if (currentRoute === "phoenix-campaigns" || currentRoute === "Phoenix") {
        return {
          app: "phoenix",
          create: "new-phoenix-campaign",
        };
      }
      if (
        currentRoute === "transsion-campaigns" ||
        currentRoute === "Transsion home"
      ) {
        return {
          app: "transsion",
          create: "new-transsion-campaign",
        };
      }
      if (currentRoute === "ctv-campaigns" || currentRoute === "Connected TV") {
        return {
          app: "ctv",
          create: "new-ctv-campaign",
        };
      }
      if (currentRoute === "gaming-campaigns" || currentRoute === "Gaming") {
        return {
          app: "gaming",
          create: "new-gaming-campaign",
        };
      }
    },
  },
  methods: {
    //filter graph data by campaign
    filterGraphByCampaign(campaigns) {
      this.$emit("filterGraphByCampaign", campaigns);
    },
    resetCampaignsFilter() {
      this.$emit("resetCampaignsFilter");
    },
    //fetch campaigns
    async getCampaigns() {
      //Campaigns
      try {
        this.loading = true;
        await this.$store.dispatch("dsp/getAllDspCampaigns");
        this.loading = false;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    //delete campaign
    async setDeleteCampaign(id) {
      try {
        this.loading = true;
        await this.$store.dispatch("dsp/deleteCampaign", id);
        this.loading = false;

        //close modal
        //Close Modal from parent
        var modal = this.$refs.modal;
        modal.dismissFn();

        //refresh campaigns state after delete
        this.getCampaigns();

        this.$notify({
          title: "Campaign deleted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem deleting the campaign. Please try again later",
          group: "errors",
        });
      } finally {
        this.loading = false;
      }
    },

    async updateCampaignStatus(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateCampaignStatus", body);
        this.$store.commit("makingApiRequest", false);

        //refresh campaigns state after delete
        this.getCampaigns();

        this.$notify({
          title: "Status updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem updating campaign status. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    //Submit campaign banners
    async submitCampaignBanners(campaignID) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitCampaignBanners", campaignID);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Banners submitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting banners. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }

      //Close Modal from parent
      var modal = this.$refs.modal;
      modal.dismissFn();
    },
  },
};
