import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import facebook from "./modules/facebook";
import dsp from "./modules/dsp";
import billing from "./modules/billing";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state,
  mutations,
  actions,
  getters,
  plugins: [
    createPersistedState()
  ],
  modules: {
    facebook,
    dsp,
    billing
  }
});