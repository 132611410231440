import Client from "./client";
import Auth from "./auth";
import Profile from "./auth";
import Facebook from "./facebook";
import Google from "./dsp";
import Users from "./users";
import Billing from "./billing";

const requiresAuth = true;

export default {
  client: new Client(),
  auth: new Auth(new Client()),
  facebook: new Facebook(new Client()),
  dsp: new Google(new Client()),
  users: new Users(new Client()),
  billing: new Billing(new Client()),
  profile: new Profile(new Client(requiresAuth)),
};
