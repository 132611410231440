class Billing {
    constructor(client) {
        this.client = client;
    }

    //Customers
    getCustomers() {
        return this.client.get(`billing/customers`);
    }

    getCustomer(id) {
        return this.client.get(`billing/customers/${id}`);
    }

    createCustomer(body) {
        return this.client.post(`billing/customers/`, body);
    }

    updateCustomer({ id, body }) {
        return this.client.put(`billing/customers/${id}`, body);
    }

    deleteCustomer(id) {
        return this.client.delete(`billing/customers/${id}`);
    }

    //Invoices
    getInvoices({ page }) {
        return this.client.get(`billing/invoices?page=${page}`);
    }

    //Invoices Overview
    getInvoicesOverview() {
        return this.client.get(`billing/invoices/count`);
    }

    getInvoice(id) {
        return this.client.get(`billing/invoices/${id}`);
    }

    createInvoice({ body }) {
        return this.client.post(`billing/invoices`, body);
    }

    updateInvoice({ id, body }) {
        return this.client.put(`billing/invoices/${id}`, body);
    }

    deleteInvoice(id) {
        return this.client.delete(`billing/invoices/${id}`);
    }

    //Purchase Orders
    getPOs() {
        return this.client.get(`billing/purchase-orders`);
    }

    getPO(id) {
        return this.client.get(`billing/purchase-orders/${id}`);
    }

    createPurchaseOrder(body) {
        return this.client.post(`billing/purchase-orders/`, body);
    }

    updatePurchaseOrder({ id, body }) {
        return this.client.put(`billing/purchase-orders/${id}`, body);
    }

    deletePurchaseOrder(id) {
        return this.client.delete(`billing/purchase-orders/${id}`);
    }
}

export default Billing;