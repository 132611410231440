class Facebook {
  constructor(client) {
    this.client = client;
  }
  // Accounts
  getAllAdaccounts() {
    return this.client.get(`facebook/ad_accounts`); // facebook/business/owned_ad_accounts + facebook/business/owned_ad_accounts
  }
  getOneAdaccount(adAccountId) {
    return this.client.get(`facebook/ad_accounts/${adAccountId}`);
  }
  getAdaccountInsightsReportId({ adAccountId, fields, date_preset }) {
    const body = { fields, date_preset }
    return this.client.put(`facebook/ad_accounts/${adAccountId}/insights/async`, body);
  }
  checkReportStatus({ report_run_id }) {
    return this.client.get(`facebook/reporting/${report_run_id}`);
  }
  getAdaccountInsights({ report_run_id }) {
    return this.client.get(`facebook/reporting/${report_run_id}/insights`);
  }

  // Campaigns
  getAllCampaigns(adAccountId) {
    return this.client.get(`facebook/${adAccountId}/campaigns`);
  }
  //get mediapal social campaigns
  getMediaPalSocialCampaigns() {
    return this.client.get(`facebook/mediapal/client_campaigns`);
  }
  getOneCampaign({ adAccountId, campaignId }) {
    return this.client.get(`facebook/${adAccountId}/campaigns/${campaignId}`);
  }
  getCampaignInsights({ adAccountId, campaignId }) {
    return this.client.put(
      `facebook/${adAccountId}/campaigns/${campaignId}/insights`,
      { date_preset: "maximum" }
    );
  }
  getCampaignInsightsByDateRange({ adAccountId, campaignId, time_range }) {
    return this.client.put(`facebook/${adAccountId}/campaigns/${campaignId}/insights`, { time_range: time_range })
  }
  getTimeBasedCampaignInsights({
    adAccountId,
    campaignId,
    date_preset,
    time_increment,
    breakdowns
  }) {
    const body = { date_preset: date_preset, time_increment: time_increment, breakdowns };
    return this.client.put(
      `facebook/${adAccountId}/campaigns/${campaignId}/insights`,
      body
    );
  }

  getCombinedFacebookCampaignInsights({
    adAccountId,
    campaignId,
    date_preset,
    breakdowns,
    action_breakdowns
  }) {
    const body = { date_preset: date_preset, breakdowns: breakdowns, action_breakdowns: action_breakdowns };
    return this.client.put(
      `facebook/${adAccountId}/campaigns/${campaignId}/insights_breakdowns`,
      body
    );
  }
  createCampaign({ ad_account_id, body }) {
    return this.client.post(`facebook/act_${ad_account_id}/campaigns`, body);
  }

  updateCampaign({ ad_account_id, campaign_id, body }) {
    return this.client.post(`facebook/act_${ad_account_id}/campaigns/${campaign_id}`, body)
  }

  deleteCampaign({ campaign_id, ad_account_id }) {
    return this.client.delete(`facebook/${ad_account_id}/campaigns/${campaign_id}`);
  }

  // Adsets
  getAllAdsets(adAccountId) {
    return this.client.get(`facebook/${adAccountId}/adsets`);
  }
  getFacebookAdset({ adAccountId, adsetId }) {
    return this.client.get(`facebook/${adAccountId}/adsets/${adsetId}`);
  }
  getAdsetInsights({ adAccountId, adsetId }) {
    return this.client.put(
      `facebook/${adAccountId}/adsets/${adsetId}/insights`,
      { date_preset: "maximum" }
    );
  }
  getTimeBasedAdsetInsights({
    adAccountId,
    adsetId,
    date_preset,
    time_increment
  }) {
    const body = { date_preset: date_preset, time_increment: time_increment };
    return this.client.put(
      `facebook/${adAccountId}/adsets/${adsetId}/insights`,
      body
    );
  }
  getDatedAdsetinsights({ adAccountId, adsetId, time_range }) {
    return this.client.put(
      `facebook/${adAccountId}/adsets/${adsetId}/insights`, { time_range: time_range }
    );
  }
  getRefinedAdsetInsights({ adAccountId, adsetId, date_preset, breakdowns }) {
    const body = { date_preset, breakdowns }
    return this.client.put(
      `facebook/${adAccountId}/adsets/${adsetId}/insights`,
      body
    );
  }
  getFacebookAdsets({ adAccountId, campaignId }) {
    return this.client.get(
      `facebook/act_${adAccountId}/campaigns/${campaignId}/adsets`
    );
  }
  createAdset({ adAccountId, body }) {
    return this.client.post(`facebook/act_${adAccountId}/adsets`, body);
  }
  updateAdset({ adAccountId, adSetId, body }) {
    return this.client.post(`facebook/act_${adAccountId}/adsets/${adSetId}`, body);
  }

  deleteAdset({ adAccountId, adSetId }) {
    return this.client.delete(`facebook/act_${adAccountId}/adsets/${adSetId}`);
  }

  // Ads
  postFacebookAd({ adAccountId, body }) {
    return this.client.post(`facebook/${adAccountId}/ads`, body);
  }

  getFacebookAds({ adAccountId, campaignId }) {
    return this.client.get(
      `facebook/${adAccountId}/campaigns/${campaignId}/ads`
    );
  }
  getFacebookAd({ adAccountId, ad_id }) {
    return this.client.get(`facebook/${adAccountId}/ads/${ad_id}`);
  }
  getFacebookAdInsights({ adAccountId, ad_id }) {
    return this.client.put(`facebook/${adAccountId}/ads/${ad_id}/insights`, { date_preset: "maximum" });
  }
  editFacebookAd(req_body) {
    const adAccountId = req_body.adAccountId
    const ad_id = req_body.ad_id
    const body = { name: req_body.name, status: req_body.status }
    return this.client.post(`facebook/${adAccountId}/ads/${ad_id}`, body);
  }
  deleteFacebookAd({ adAccountId, ad_id }) {
    return this.client.delete(`facebook/${adAccountId}/ads/${ad_id}`);
  }
  getFacebookAdsetAds({ adAccountId, adsetId }) {
    return this.client.get(`facebook/${adAccountId}/adsets/${adsetId}/ads`);
  }
  getAdsetAds({ adAccountId, adsetId }) {
    return this.client.get(`facebook/${adAccountId}/adsets/${adsetId}/ads`);
  }
  getAdCreatives({ adAccountId, ad_id }) {
    return this.client.get(`facebook/${adAccountId}/ads/${ad_id}/creatives`);
  }
  getSingleCreative({ adAccountId, creativeId }) {
    return this.client.get(
      `facebook/${adAccountId}/ad_creatives/${creativeId}`
    );
  }

  // Client Pages
  getClientPages() {
    return this.client.get("facebook/business/owned_pages");
  }


  getAllCustomAudiences(adAccountId) {
    return this.client.get(`facebook/custom_audiences/${adAccountId}`);
  }

  getAllCountries(body) {
    return this.client.put(`facebook/search`, body);
  }

  getLanguages(bodylanguage) {
    return this.client.put(`facebook/search`, bodylanguage);
  }

  getRegions(bodyregions) {
    return this.client.put(`facebook/search`, bodyregions);
  }

  getCities(bodycities) {
    return this.client.put(`facebook/search`, bodycities);
  }

  getZipCodes(bodyZips) {
    return this.client.put(`facebook/search`, bodyZips);
  }

  getInterests(interestsBody) {
    return this.client.put(`facebook/search`, interestsBody);
  }

  getBehaviors(behaviorsBody) {
    return this.client.put(`facebook/search`, behaviorsBody);
  }

  getEmployers(work_params) {
    return this.client.put(`facebook/search`, work_params);
  }

  getLifeEvents(lifeParams) {
    return this.client.put(`facebook/search`, lifeParams);
  }

  getIndustries(industryparams) {
    return this.client.put(`facebook/search`, industryparams);
  }

  getDemographics(demo_params) {
    return this.client.put(`facebook/search`, demo_params);
  }

  getIncome(income_params) {
    return this.client.put(`facebook/search`, income_params);
  }

  getMajors(majors_params) {
    return this.client.put(`facebook/search`, majors_params);
  }

  getJobTitles(job_titles_params) {
    return this.client.put(`facebook/search`, job_titles_params);
  }

  getUserDevice(device_params) {
    return this.client.put(`facebook/search`, device_params);
  }

  getUserOs(user_os_params) {
    return this.client.put(`facebook/search`, user_os_params);
  }

  // Ad Creatives //now  onow
  postAdCreative({ object_story_spec, adAccountId }) {
    return this.client.post(`facebook/act_${adAccountId}/ad_creatives`, object_story_spec);
  }

  // Ad Preview
  postPreviewOption({ adAccountId, ad_format, creativeId }) {
    return this.client.put(`facebook/${adAccountId}/ad_creatives/${creativeId}/previews`, { ad_format: ad_format })
  }

  //Ad Videos
  getAdVideo({ adAccountId, video_id }) {
    return this.client.get(`facebook/${adAccountId}/ad_videos/${video_id}`);
  }
  postAdVideo({ adAccountId, video_data }) {
    return this.client.post(`facebook/${adAccountId}/ad_videos`, video_data);
  }

  // Ad Images 
  getAdImages(adAccountId) {
    return this.client.put(`facebook/${adAccountId}/ad_images`);
  }

  getAdImage({ adAccountId, hashes }) {
    return this.client.put(`facebook/${adAccountId}/ad_images`, { hashes: hashes });
  }

  uploadAdImages({ adAccountId, bytes }) {
    return this.client.post(`facebook/${adAccountId}/ad_images`, { bytes: bytes }); // let body = { bytes: "" }
  }

  deleteAdImages(adAccountId) {
    return this.client.delete(`facebook/${adAccountId}/ad_images`);
  }

  // Landing Page
  getFacebookReports(group_by) {
    return this.client.put(`facebook/reports/charts_insights`, { group_by: group_by });
  }

  getFacebookCardsInsights() {
    return this.client.get(`facebook/reports/cards_insights`);
  }

  getTodayInsights(){
    return this.client.get(`facebook/reporting/facebook_cards_insights`)
  }

  updateCampaignFees ({ campaign_id, data }){
    return this.client.post(`facebook/mediapal/${campaign_id}/campaign_fees`, data)
  }
}

export default Facebook;
