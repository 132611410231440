<template>
  <v-row>
    <v-tab v-for="([tab, link, icon], i) in tabs" :key="i" :href="`#${link}`">
      <v-badge
        color="grey lighten-2 black--text "
        :content="count[link]"
        inline
      >
        <v-icon left small>{{ icon }}</v-icon>
        <span class="text-capitalize tab-text">{{ tab }}</span>
      </v-badge>
    </v-tab>
  </v-row>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    count: {
      type: Object,
    },
  },
  data: () => ({}),
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
};
</script>
