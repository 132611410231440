<template>
  <v-row class="page-height">
    <v-col class="d-flex flex-column align-center justify-center">
      <div class="custom-width">
        <span class="d-flex align-center custom-height">
          <h1 class="mb-7">
            Get started with a <br />
            free account
          </h1>
        </span>
        <h4 class="mb-4">
          Already have an account?
          <router-link to="/login" class="blue-text"><u>Log in</u></router-link>
        </h4>
        <v-text-field
          label="Username"
          prepend-inner-icon="mdi-account-outline"
          class="mt-5"
          color="#C9CAD1"
        ></v-text-field>
        <v-text-field
          label="Email"
          prepend-inner-icon="mdi-email-outline"
          class="my-10"
          type="email"
        ></v-text-field>
        <v-text-field
          label="Password"
          prepend-inner-icon="mdi-lock-outline"
          class="my-10"
          type="password"
        ></v-text-field>
        <span class="mt-10 d-flex" id="checkbox">
          <v-checkbox light></v-checkbox>
          <p>
            I agree to the <span class="blue-text">terms and conditions</span>
          </p>
        </span>
        <v-row class="d-flex align-center mt-5">
          <v-col class="d-flex">
            <h4>Show Password</h4>
            <v-switch dense class="ml-3"></v-switch>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn class="btn-gradient">GetStarted!</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col style="padding: 0">
      <welcome-video />
    </v-col>
  </v-row>
</template>

<script>
import WelcomeVideo from "../../components/WelcomeVideo.vue";
export default {
  components: {
    WelcomeVideo,
  },
};
</script>